import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "../../api/axios";

export interface FilterSlice {
  itemOpen: number | null;
  serverSelect: number;
  selectCategory: number;
  paginationCart: number;
}

const initialState: FilterSlice = {
  itemOpen: null,
  selectCategory: 0,
  serverSelect: 1,
  paginationCart: 1
};
export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setSelectCategory: (state, action: PayloadAction<number>) => {
      state.selectCategory = action.payload;
    },
    setServerSelect(state, action: PayloadAction<number>) {
      state.serverSelect = action.payload;
    },
    openItem: (state, action: PayloadAction<number>) => {
      state.itemOpen = action.payload;
    },
    closeItem: (state) => {
      state.itemOpen = null;
    },
    setPaginationCart: (state, action:PayloadAction<number>) => {
      state.paginationCart = action.payload;
    }
  },
});

export const { openItem,setPaginationCart, closeItem, setServerSelect, setSelectCategory } =
  filterSlice.actions;
export const getOpenItem = (state: RootState) => state.filter.itemOpen;

export const PaginationCart = (state: RootState) => state.filter.paginationCart;

export const selectCategory = (state: RootState) => state.filter.selectCategory;

export const serverSelect = (state: RootState) => state.filter.serverSelect;

export default filterSlice.reducer;
