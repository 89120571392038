import Header from "../../components/header/Header";
import Info from "../../components/info/Info";
import Line from "../../components/line/Line";
import React, { useState } from "react";
import { MainInfoProfile } from "../../components/profile/MainInfoProfile";
import { useAppSelector } from "../../../app/hooks";
import { selectUser, selectUserStatus } from "../../../app/user/userSlice";
import { Navigate } from "react-router-dom";
import { Cart } from "../../components/profile/Cart";
import Loading from "../../components/loading/Loading";
import { History } from "../../components/profile/History";
import useDocumentTitle from "../../../hooks/useDocumentTitle";

export const Profile: React.FC = () => {
  const user = useAppSelector(selectUser);

  useDocumentTitle("MRAZ PROFILE - " + user?.name);
  const userStatus = useAppSelector(selectUserStatus);
  const [active, setActive] = useState(0);
  if (userStatus === "failed") {
    return <Navigate to={"/"} />;
  }
  if (user === null) {
    return <Loading />;
  }
  const items = ["Профиль", "История", "Корзина"];
  const cbItem = (item: string, i: number) => (
    <div
      onClick={() => setActive(i)}
      key={item}
      className={
        "px-10 py-2 rounded text-l transition-all duration-500 ease-in-out cursor-pointer select-none" +
        (active === i
          ? " bg-amber-500 scale-110 text-black font-semibold"
          : " bg-[#242424] hover:scale-110")
      }
    >
      {item}
    </div>
  );
  return (
    <>
      <Header />
      <Info />
      <Line />
      <div className="flex flex-col text-center justify-center items-center p-5 my-12 gap-10 bg-[#1B1B1B80] max-w-4xl mx-auto">
        <div className="flex justify-center items-center gap-5">
          {items.map(cbItem)}
        </div>
        {active === 0 && <MainInfoProfile user={user} />}
        {active === 1 && <History />}
        {active === 2 && <Cart />}
      </div>
    </>
  );
};
