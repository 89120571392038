// src/components/DinoGame.tsx
import React, { useState, useEffect, useCallback, useRef } from "react";
import "./DinoGame.css";

const DinoGame: React.FC = () => {
  const [isJumping, setIsJumping] = useState(false);
  const [obstacles, setObstacles] = useState<number[]>([]);
  const [score, setScore] = useState(0);
  const [canJump, setCanJump] = useState(true);
  const dinoRef = useRef<HTMLImageElement>(null);

  const jump = useCallback(() => {
    if (!isJumping && canJump) {
      setIsJumping(true);
      setCanJump(false);
      setTimeout(() => {
        setIsJumping(false);
      }, 500);
    }
  }, [isJumping, canJump]);

  const generateObstacle = useCallback(() => {
    setObstacles((prev) => [...prev, window.innerWidth]);
    setScore((prev) => prev + 1);
  }, []);

  const updateObstacles = useCallback(() => {
    setObstacles((prev) => prev.map((pos) => pos - 5));
  }, []);

  const checkCollision = useCallback(() => {
    const dinoRect = dinoRef.current?.getBoundingClientRect();
    const obstaclesRects = obstacles.map(
      (pos) =>
        document.getElementById(`obstacle-${pos}`)?.getBoundingClientRect(),
    );

    if (
      dinoRect &&
      obstaclesRects &&
      obstaclesRects.some(
        (rect) =>
          rect &&
          rect.top < dinoRect.bottom &&
          rect.bottom > dinoRect.top &&
          rect.left < dinoRect.right &&
          rect.right > dinoRect.left,
      )
    ) {
      alert(`Game Over! Score: ${score}`);
      setObstacles([]);
      setScore(0);
      setCanJump(true);
    }
  }, [obstacles, score]);

  const handleKeyPress = useCallback(
    (event: any) => {
      if (event.code === "Space") {
        jump();
      }
    },
    [jump],
  );

  const handleKeyUp = useCallback(() => {
    setCanJump(true);
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyPress, handleKeyUp]);

  useEffect(() => {
    const interval = setInterval(generateObstacle, 2000);
    return () => clearInterval(interval);
  }, [generateObstacle]);

  useEffect(() => {
    const interval = setInterval(updateObstacles, 30);
    return () => clearInterval(interval);
  }, [updateObstacles]);

  useEffect(() => {
    const interval = setInterval(checkCollision, 30);
    return () => clearInterval(interval);
  }, [checkCollision]);

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="game-container">
        <img
          ref={dinoRef}
          className={`dino ${isJumping ? "jump" : ""}`}
          onClick={jump}
          src={"hero.png"}
          alt={"Hero"}
        />
        <div className="score">Score: {score}</div>
        {obstacles.map((pos, index) => (
          <div
            key={index}
            id={`obstacle-${pos}`}
            className="obstacle"
            style={{ left: pos }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default DinoGame;
