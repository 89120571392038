import React from "react";
function Footer() {
  return (
    <footer
      className={"relative w-full bg-[#0E0E0E] text-white text-[12px] p-5"}
    >
      <div
        className={
          "flex items-center text-center justify-center content-center"
        }
      >
        <span className={"w-[600px] text-amber-500"}>
          Размещенная на настоящем сайте информация носит исключительно
          информационный характер и ни при каких условиях не является публичной
          офертой, определяемой положениями ч. 2 ст. 437 Гражданского кодекса
          Российской Федерации.
        </span>
      </div>
      <div
        className={"flex justify-center items-center content-center mt-3.5 "}
      >
        <span className={"mr-5"}>Пользовательское соглашение</span>
        <span className={"mr-5"}>Политика Конфиденциальности</span>
        <span>support@mrazrust.ru</span>
      </div>
    </footer>
  );
}

export default Footer;
