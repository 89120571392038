import React from "react";

function InfoDrop({ isDropdownOpen }: { isDropdownOpen: boolean }) {
  return (
    <div className="absolute z-40 w-full top-8 rounded-b-lg text-center flex flex-col justify-center content-center items-center">
      {/* Ваше содержимое выпадающего меню */}
      <ul className=" mt-2 py-1 w-full flex flex-col justify-center content-center items-center">
        <li
          className={
            "flex justify-center content-center items-center bg-zinc-800 px-1 w-full mb-2 py-2 cursor-pointer rounded-xl  border border-amber-300 transition-opacity duration-500 ease-in-out " +
            (!isDropdownOpen ? "opacity-0" : "opacity-100")
          }
        >
          <svg
            fill="#ffffff"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M21,19 C21.5522847,19 22,19.4477153 22,20 C22,20.5522847 21.5522847,21 21,21 L3,21 C2.44771525,21 2,20.5522847 2,20 C2,19.4477153 2.44771525,19 3,19 L21,19 Z M21,15 C21.5522847,15 22,15.4477153 22,16 C22,16.5522847 21.5522847,17 21,17 L9,17 C8.44771525,17 8,16.5522847 8,16 C8,15.4477153 8.44771525,15 9,15 L21,15 Z M21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L21,11 Z M21,7 C21.5522847,7 22,7.44771525 22,8 C22,8.55228475 21.5522847,9 21,9 L9,9 C8.44771525,9 8,8.55228475 8,8 C8,7.44771525 8.44771525,7 9,7 L21,7 Z M21,3 C21.5522847,3 22,3.44771525 22,4 C22,4.55228475 21.5522847,5 21,5 L3,5 C2.44771525,5 2,4.55228475 2,4 C2,3.44771525 2.44771525,3 3,3 L21,3 Z"
            />
          </svg>
          <span className={"ml-1"}>Правила</span>
        </li>
        <li
          className={
            "flex justify-center content-center items-center bg-zinc-800 px-1 w-full mb-2 py-2 cursor-pointer rounded-xl  border border-amber-300 transition-opacity duration-500 ease-in-out " +
            (!isDropdownOpen ? "opacity-0" : "opacity-100")
          }
        >
          Топ
        </li>
        {/* Другие пункты меню */}
      </ul>
    </div>
  );
}

export default InfoDrop;
