import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getServers } from "../../../app/servers/serverSlice";
import { serverSelect, setServerSelect } from "../../../app/filter/filterSlice";

function ServerSelector() {
  const servers = useAppSelector(getServers);
  const select = useAppSelector(serverSelect);
  const dispatch = useAppDispatch();
  const onClickSelectServer = (id: number) => {
    dispatch(setServerSelect(id));
  };
  return (
    <div
      className={
        "w-full flex flex-col text-white justify-start content-start items-start"
      }
    >
      <span className={"text-left"}>Выберите сервер</span>
      <div className={"grid grid-cols-1 w-full gap-2"}>
        {servers
          .filter((p) => p.isCategory)
          .map((server, _) => (
            <button
              key={server.id}
              onClick={() => onClickSelectServer(server.id ?? 0)}
              className={
                select === server.id
                  ? "w-full text-center h-10 bg-[#E28801] text-[#0E0E0E] font-bold rounded-md col-span-1"
                  : "w-[240px] text-[14px] text-center h-10 bg-[#1B1B1B80] text-white transition-all duration-500 ease-in-out"
              }
            >
              {server.categoryName}
            </button>
          ))}
      </div>
    </div>
  );
}

export default ServerSelector;
