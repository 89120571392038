import React, { useEffect } from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  PaginationCart,
  setPaginationCart,
} from "../../../app/filter/filterSlice";

interface IPagination {
  maxCount: number;
  step: number;
}

export const Pagination: React.FC<IPagination> = ({ maxCount, step }) => {
  const dispatch = useAppDispatch();
  const activePage = useAppSelector(PaginationCart);
  const maxCountPage = Math.ceil(maxCount / step);
  const styles = {
    buttonActive:
      "text-2xl text-center text-black bg-amber-500 cursor-pointer rounded-xl px-6 text-center flex items-center",
    buttonOff:
      "text-2xl text-black bg-gray-400 cursor-pointer rounded-xl px-6 text-center flex items-center",
    flexDiv: "flex gap-12 m-4 justify-center items-center",
    text: "cursor-pointer select-none",
    textActive: "cursor-pointer select-none font-extrabold text-rose-500",
  };

  const nextPage = (step = 1) => {
    dispatch(setPaginationCart(activePage + step));
  };
  const prevPage = (step = 1) => {
    dispatch(setPaginationCart(activePage - step));
  };
  useEffect(() => {
    dispatch(setPaginationCart(1));
  }, []);

  if (maxCount <= step) {
    return <></>;
  } else if (activePage === 1) {
    return (
      <div className={styles.flexDiv}>
        <div className={styles.buttonOff}>
          <KeyboardDoubleArrowLeftIcon />
        </div>
        <div className={styles.textActive}>{activePage}</div>
        <div className={styles.text} onClick={() => nextPage()}>
          {activePage + 1}
        </div>
        {maxCountPage >= activePage + 2 && (
          <div className={styles.text} onClick={() => nextPage(2)}>
            {activePage + 2}
          </div>
        )}
        <div onClick={() => nextPage()} className={styles.buttonActive}>
          <KeyboardDoubleArrowRightIcon />
        </div>
      </div>
    );
  } else if (activePage === maxCountPage) {
    return (
      <div className={styles.flexDiv}>
        <div onClick={() => prevPage()} className={styles.buttonActive}>
          <KeyboardDoubleArrowLeftIcon />
        </div>
        {activePage - 2 <= maxCountPage && activePage - 2 !== 0 && (
          <>
            <div className={styles.text} onClick={() => prevPage(2)}>
              {activePage - 2}
            </div>
          </>
        )}
        <div className={styles.text} onClick={() => prevPage()}>
          {activePage - 1}
        </div>
        <div className={styles.textActive}>{activePage}</div>
        <div className={styles.buttonOff}>
          <KeyboardDoubleArrowRightIcon />
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.flexDiv}>
        <div onClick={() => prevPage()} className={styles.buttonActive}>
          <KeyboardDoubleArrowLeftIcon />
        </div>
        <div className={styles.text} onClick={() => prevPage()}>
          {activePage - 1}
        </div>
        <div className={styles.textActive}>{activePage}</div>
        {activePage + 1 <= maxCountPage && (
          <>
            <div className={styles.text} onClick={() => nextPage()}>
              {activePage + 1}
            </div>
            <div onClick={() => nextPage()} className={styles.buttonActive}>
              <KeyboardDoubleArrowRightIcon />
            </div>
          </>
        )}
      </div>
    );
  }
};
