import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getPayment,
  selectTransaction,
  updatePayment,
} from "../../../app/transaction/transactionSlice";
import { Navigate, useParams } from "react-router-dom";

export const Payment = (): JSX.Element => {
  const transaction = useAppSelector(selectTransaction);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getPayment(Number(id)));
      } catch (e) {
        console.error("Error fetching payment:", e);
      }
    };

    fetchData();
  }, [dispatch, id]);
  if (!Number(id)) {
    return <Navigate to={"/"} />;
  }

  if (transaction.status === "failed") {
    return <Navigate to={"/"} />;
  }
  if (transaction.id === 0) {
    return <div className={"text-white"}>Error</div>;
  }
  const handlePayment = async (type: string) => {
    const { payload } = await dispatch(
      updatePayment({ id: transaction.id, type: type }),
    );
    console.log(payload);
    window.location.href = payload as string;
  };
  return (
    <div className="overflow-x-clip bg-[#0e0e0e] min-h-screen min-w-full flex flex-col sm:flex-row justify-center sm:justify-normal  sm:content-normal content-center sm:items-start items-center">
      <div className="relative sm:w-[1313px] sm:h-[341px] sm:top-[50px] sm:left-[50px] flex flex-col items-center sm:items-start ">
        <span
          className={
            "mt-5 text-center sm:text-left font-bold text-[#6f6a6a] text-[48px] tracking-[0] leading-[normal]"
          }
        >
          Платеж №{transaction.id}
        </span>
        <span
          className={
            "font-medium text-[#6f6a6a] text-[25px] tracking-[0] leading-[normal] whitespace-nowrap"
          }
        >
          Выберите тип оплаты
        </span>
        <span
          className={
            "sm:mt-10 font-medium text-[#6f6a6a] text-[20px] tracking-[0] leading-[normal] whitespace-nowrap"
          }
        >
          Сумма пополнения
        </span>
        <span
          className={
            "mb-5 font-semibold text-[#6e6a6a] text-[40px] tracking-[0] leading-[normal] whitespace-nowrap"
          }
        >
          {transaction.amount} ₽
        </span>
      </div>
      <div
        className={
          "w-full h-full sm:w-[1000px] sm:h-[800px] mb-10 sm:mr-20 flex items-center content-center justify-center"
        }
      >
        <div
          className={
            "w-full sm:w-[750px] md:w-[750px] mt-10 mb-10 grid xl:grid-cols-4 gap-x-[25px] gap-y-[25px] grid-cols-1 lg:grid-cols-3 md:grid-cols-2"
          }
        >
          <div
            onClick={() => handlePayment("tome")}
            className="px-2 py-5 font-bold col-span-1 m-auto rounded-xl bg-[#535353] w-[255px] h-[100px] sm:w-[175px] sm:h-[100px] flex flex-col flex-shrink-0 justify-center items-center content-center"
          >
            <div
              className={
                "w-[90%] flex content-center items-center justify-center"
              }
            >
              <img alt={"tome"} src={"/tome.png"} className={"w-10 h-10"} />
              <span>TOME</span>
            </div>
            <span className={"text-white font-light opacity-80 text-center"}>
              СБП, Карта и Qiwi
            </span>
          </div>
          <div
            onClick={() => handlePayment("lava")}
            className="px-2 py-5 font-bold col-span-1 m-auto rounded-xl bg-[#535353] w-[255px] h-[100px] sm:w-[175px] sm:h-[100px] flex flex-col flex-shrink-0 justify-center items-center content-center"
          >
            <div
              className={
                "w-[90%] flex content-center items-center justify-center"
              }
            >
              <img alt={"tome"} src={"/lava.png"} className={"w-9 h-9 mr-3"} />
              <span>LAVA</span>
            </div>
            <span className={"text-white font-light opacity-80 text-center"}>
              SberPay, Карта и Qiwi
            </span>
          </div>
          <div
            onClick={() => handlePayment("freekassa")}
            className="px-2 py-5 font-bold col-span-1 m-auto rounded-xl bg-[#535353] w-[255px] h-[100px] sm:w-[175px] sm:h-[100px] flex flex-col flex-shrink-0 justify-center items-center content-center"
          >
            <div
              className={
                "w-[90%] flex content-center items-center justify-center"
              }
            >
              <img alt={"tome"} src={"/fk.png"} className={"w-9 h-9 mr-3"} />
              <span>FREEKASSA</span>
            </div>
            <span
              className={
                "text-white font-light opacity-80 text-center text-[12px]"
              }
            >
              SberPay, Карта(Казахстан и РУ) и Крипта
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Payment;
