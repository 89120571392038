import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Banner from "../../components/banner/Banner";
import Servers from "../../components/servers/Servers";
import Socials from "../../components/socials/Socials";
import Info from "../../components/info/Info";
import Line from "../../components/line/Line";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getAllItems, getItems } from "../../../app/items/itemSlice";
import ServerSelector from "../../components/serverSelector/ServerSelector";
import Categories from "../../components/categories/Categories";
import {
  getAllServers,
  getServerStatus,
} from "../../../app/servers/serverSlice";
import Container from "./Container";
import qs from "qs";
import {
  openItem,
  setSelectCategory,
  setServerSelect,
} from "../../../app/filter/filterSlice";
import useFilter from "../../../hooks/useFilter";
import Loading from "../../components/loading/Loading";

function Home() {
  const dispath = useAppDispatch();
  const { status } = useAppSelector(getItems);
  const serverStatus = useAppSelector(getServerStatus);
  useEffect(() => {
    dispath(getAllItems());
    dispath(getAllServers());
  }, []);
  React.useEffect(() => {
    if (window.location.search) {
      const { category, server, item } = qs.parse(
        window.location.search.substring(1),
      );
      if (category) {
        dispath(setSelectCategory(Number(category)));
      }
      if (server) {
        dispath(setServerSelect(Number(server)));
      }
      if (item) {
        dispath(openItem(Number(item)));
      }
    }
  }, []);
  useFilter();
  if (status === "loading" || serverStatus === "loading") {
    return (
      <>
        <Header />
        <Info />
        <Line />
        <Loading />
      </>
    );
  }
  return (
    <div className={"flex flex-col justify-center items-center"}>
      <Header />
      <Info />
      <Line />
      <Banner />

      <div
        className={
          "bg-black flex flex-col xl:flex-row justify-center content-center items-center"
        }
      >
        <div
          className={
            "xl:self-start mb-3 xl:mt-10 w-[300px] xl:mr-1 order-2 xl:order-[-1] flex flex-col justify-center items-center content-center"
          }
        >
          <Servers />
          <Socials />
        </div>
        <Container />
        <div
          className={
            "xl:self-start mb-3 xl:mt-10 w-[270px] xl:ml-4  flex flex-col justify-center items-center content-center"
          }
        >
          <ServerSelector />
          <Categories />
        </div>
        {/*<Link*/}
        {/*  to="/maze"*/}
        {/*  className={*/}
        {/*    "text-black w-[300px] xl:mt-10 xl:ml-1 order-3 flex flex-col justify-center items-center content-center"*/}
        {/*  }*/}
        {/*>*/}
        {/*  1*/}
        {/*</Link>*/}
      </div>

      <Line />
      <Footer />
    </div>
  );
}

export default Home;
