import React, { useEffect, useState } from "react";
import Loading from "../../../../../components/loading/Loading";
import axios from "../../../../../../api/axios";
import { ILogAdminResponse } from "../../../../../../api/admin.entity";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function History() {
  const [items, setItems] = useState<ILogAdminResponse>();
  const [sortBy, setSortBy] = useState<string>();
  const fetchUsers = async () => {
    const { data } = await axios.get<ILogAdminResponse>(
      `/admin/allLogs?page=1&limit=10${!sortBy ? "" : `&sortBy=${sortBy}`}`,
    );
    setItems(data);
  };
  useEffect(() => {
    fetchUsers();
  }, [sortBy]);
  const onHandleSort = async (sort: string) => {
    let type = "asc";
    if (sortBy && sortBy.includes(sort) && sortBy.split("_")[1] === "asc") {
      type = "desc";
    }

    console.log(sortBy?.includes(sort));
    setSortBy(`${sort}_${type}`);
  };
  if (!items) {
    return <Loading />;
  }
  const splitSort = sortBy?.split("_") ?? "12";
  return (
    <div className={"w-full h-full"}>
      <table className={"min-w-full h-full"}>
        <thead>
          <tr
            className={
              "select-none text-amber-500 font-bold bg-[#242424] h-10 rounded-t-full rounded-tl-full rounded-tr-full border-b border-b-slate-500"
            }
          >
            <th
              onClick={() => onHandleSort("id")}
              className={"pl-4 pr-4 content-center"}
            >
              ID
              {sortBy?.includes("id") && (
                <KeyboardArrowDownIcon
                  className={
                    "scale-50" +
                    (sortBy?.split("_")[1] === "desc" ? " rotate-180" : "")
                  }
                />
              )}
            </th>
            <th
              onClick={() => onHandleSort("userId")}
              className={"pl-4 pr-4 content-center"}
            >
              Пользователь
              {sortBy?.includes("userId") && (
                <KeyboardArrowDownIcon
                  className={
                    "scale-50" +
                    (sortBy?.split("_")[1] === "desc" ? " rotate-180" : "")
                  }
                />
              )}
            </th>
            <th
              onClick={() => onHandleSort("count")}
              className={"pl-4 pr-4 content-center"}
            >
              Сумма
              {sortBy?.includes("count") && (
                <KeyboardArrowDownIcon
                  className={
                    "scale-50" +
                    (sortBy?.split("_")[1] === "desc" ? " rotate-180" : "")
                  }
                />
              )}
            </th>
            <th
              onClick={() => onHandleSort("custom")}
              className={"pl-4 pr-4 content-center"}
            >
              Подробнее
              {sortBy?.includes("custom") && (
                <KeyboardArrowDownIcon
                  className={
                    "scale-50" +
                    (sortBy?.split("_")[1] === "desc" ? " rotate-180" : "")
                  }
                />
              )}
            </th>{" "}
            <th
              onClick={() => onHandleSort("createAt")}
              className={"pl-4 pr-4 content-center"}
            >
              Дата создания
              {sortBy?.includes("createAt") && (
                <KeyboardArrowDownIcon
                  className={
                    "scale-50" +
                    (sortBy?.split("_")[1] === "desc" ? " rotate-180" : "")
                  }
                />
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {items.items.map((item) => (
            <tr
              key={item.id}
              className={
                "odd:bg-[#1C1C1C] even:bg-[#242424] " +
                "text-white bg-[#1C1C1C] text-center h-10 border-b border-b-slate-500 last:border-b-0 last:rounded-bl-full last:rounded-br-full last:rounded-b-full"
              }
            >
              <td className={"pl-4 pr-4"}>{item.id}</td>
              <td className={"pl-4 pr-4"}>{item.user}</td>
              <td className={"pl-4 pr-4"}>{item.count}</td>
              <td className={"pl-4 pr-4"}>{item.custom}</td>
              <td className={"pl-4 pr-4"}>
                {new Date(item.createAt).toLocaleString("ru-RU")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default History;
