import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {getCart, getCartList} from "../../../app/cart/cartSlice";
import React, {useEffect} from "react";
import Loading from "../loading/Loading";
import {Pagination} from "./Pagination";
import {PaginationCart} from "../../../app/filter/filterSlice";

export const Cart: React.FC = () => {
  const dispatch = useAppDispatch();
  const cart = useAppSelector(getCart);
  const page = useAppSelector(PaginationCart);
  useEffect(() => {
    dispatch(getCartList({ list: page, limit: 5 }));
  }, [page]);
  const cartTitles = [" ", "Название", "Количество", "Сумма", "Статус"];
  const titlesMap = (title: string) => (
    <th
      scope="col"
      className="px-6 font-bold bg-[#242424] py-4 text-center text-amber-500 "
    >
      {title}
    </th>
  );
  return (
    <>
      {cart.status === "loading" && cart.items.length === 0 && (
        <div className="w-[200px] h-[200px]">
          <Loading />
        </div>
      )}
      <table className="min-w-full">
        <thead className="bg-slate-50 border-b border-slate-200">
          <tr>{cartTitles.map(titlesMap)}</tr>
        </thead>
        <tbody>
          {cart.items.map((item) => {
            return (
              <tr
                key={item.id}
                className="px-6 py-4 odd:bg-[#1C1C1C] even:bg-[#242424] select-none"
              >
                <td className="px-6 py-4 text-center w-12 h-12">
                  <img
                    className="max-w-3xl max-h-12"
                    src={item.itemImage}
                    alt={item.itemName}
                  />
                </td>
                <td className="px-6 py-4 text-center font-[16px]">
                  {item.itemName}
                </td>
                <td className="px-6 py-4 text-center font-[16px]">
                  {item.count}
                </td>
                <td className="px-6 py-4 text-center font-[16px]">
                  {item.cost}
                </td>
                <td className="px-6 py-4 text-center font-[16px]">
                  {item.status === "pending"
                    ? "Ждет получения"
                    : new Date(item.status).toLocaleString("ru-RU")}
                </td>
              </tr>
            );
          })}
          {5 - cart.items.length !== 0 &&
              Array(5 - cart.items.length).map((i) => (
                  <tr
                      key={cartTitles[i]}
                      className="px-6 py-4 odd:bg-[#1C1C1C] even:bg-[#242424] select-none"
                  ></tr>
              ))}
        </tbody>
      </table>
      <Pagination maxCount={cart.allCount} step={5} />
    </>
  );
};
