import React, { useEffect, useState } from "react";
import axios from "../../../../api/axios";
import { useAppDispatch } from "../../../../app/hooks";
import { fetchSession } from "../../../../app/user/userSlice";
import Notification from "../../notify/Notify";
export default function PopupPromo({
  hidden,
  setHidden,
}: {
  hidden: boolean;
  setHidden: (hidden: boolean) => void;
}) {
  const dispatch = useAppDispatch();
  const [anim, setAnim] = useState(true);
  const [disable, setDisable] = useState(false);
  const [notification, setNotification] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);

  const showNotification = (message: string, type: "success" | "error") => {
    setNotification({ message, type });
  };
  useEffect(() => {
    setAnim(hidden);
  }, []);
  const onChangePromo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const [value, setValue] = useState("");
  const handlePromo = async () => {
    try {
      setDisable(true);
      const { data } = await axios.patch(`/promo/use/${value}`);
      console.log(data);
      dispatch(fetchSession());
      showNotification("Промокод успешно использован", "success");
    } catch (error: any) {
      console.log(error.response.data.message);
      showNotification(error.response.data.message, "error");
    }
    setDisable(false);
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className={(hidden ? "hidden " : "") + "relative"}>
      <div className="fixed inset-0 z-50 overflow-hidden">
        <div
          className={
            "absolute inset-1 bg-stone-900 blur-[90px] transition-all duration-1000 ease-in-out" +
            (anim ? " opacity-0" : " opacity-100")
          }
        ></div>
        {notification && (
          <Notification
            message={notification.message}
            type={notification.type}
            onClose={() => setNotification(null)}
          />
        )}
        <div className="absolute inset-0 flex items-center justify-center text-white">
          <div
            className={
              "promo border border-amber-500 rounded-2xl bg-[#0E0E0E] flex flex-col justify-between content-center items-center w-[330px] sm:min-w-[255px] sm:min-h-[450px] transition-all duration-1000 ease-in-out transform " +
              (anim ? "-translate-y-full" : "translate-y-0") +
              (anim ? " opacity-0" : " opacity-100")
            }
          >
            <span className={"mt-5 text-white text-[20px] font-black"}>
              Промокоды
            </span>
            <div
              className={
                "w-[90%] mb-5 leading-4 flex-shrink-0 min-h-[209px] bg-neutral-900 rounded-md border border-orange-600 flex justify-center content items-center"
              }
            >
              <p className={"px-5 text-white text-[12px] font-extralight"}>
                Тут вы можете активировать промокод если он у вас имеется Если
                вы забустите дискорд сервер, каждый вайп вы будете получать
                промокоды.
              </p>
            </div>
            <div className="w-full h-full flex flex-col justify-center content-center items-center ">
              <div
                className={
                  "w-[90%] h-5 flex flex-col justify-center content-center items-center mb-5"
                }
              >
                <span
                  className={
                    "w-full text-left text-white text-[14px] font-extralight"
                  }
                >
                  Промокод
                </span>
                <div
                  className={
                    "w-full bg-neutral-900 rounded-lg border border-orange-600 flex justify-between items-center content-between"
                  }
                >
                  <input
                    autoFocus={true}
                    onChange={onChangePromo}
                    type="text"
                    placeholder={"Сюда промокод"}
                    className={
                      "pl-2 text-[16px] font-light w-full h-full bg-transparent focus:border-none shadow-none"
                    }
                    value={value}
                  />
                </div>
              </div>
              <div
                className={
                  "w-[90%] h-full flex justify-between content-between items-center mb-2"
                }
              >
                <button
                  onClick={() => {
                    setAnim(true);
                    setTimeout(() => setHidden(!hidden), 800);
                  }}
                  className={
                    "mb-4 mr-2 w-[132px] h-[32px] relative bg-[#FF5318] rounded-md text-stone-950 text-[12px] font-black"
                  }
                >
                  ЗАКРЫТЬ
                </button>
                <button
                  disabled={disable}
                  onClick={handlePromo}
                  className={
                    "mb-4 ml-2 w-[132px] h-[32px] relative bg-[#FF5318] rounded-md text-[12px] font-black"
                  }
                >
                  ИСПОЛЬЗОВАТЬ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
