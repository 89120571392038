import React, { useEffect, useState } from "react";
import Loading from "../../../../../components/loading/Loading";
import axios from "../../../../../../api/axios";
import {
  IUserAdmin,
  IUserAdminResponse,
} from "../../../../../../api/admin.entity";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "@mui/material/Modal";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { Link } from "react-router-dom";
import Notification from "../../../../../components/notify/Notify";

function Users() {
  const [users, setUsers] = useState<IUserAdminResponse>();
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");

  const [currentUser, setCurrentUser] = useState<IUserAdmin>();
  const [notification, setNotification] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);
  const fetchUsers = async () => {
    const { data } = await axios.get<IUserAdminResponse>(
      "/admin/allUsers?page=1&limit=10",
    );
    setUsers(data);
  };
  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const showNotification = (message: string, type: "success" | "error") => {
    setNotification({ message, type });
  };

  if (!users) {
    return <Loading />;
  }
  const handleClose = (event: {}, reason: string) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      setOpenModal(false);
    }
  };
  return (
    <div className={"w-full h-full"}>
      <div className={"w-full flex mb-4 justify-between items-center"}>
        <div className={"flex gap-3 h-full"}>
          <input
            className={
              "w-full rounded-3xl border-2 border-emerald-600 bg-transparent px-4 py-2"
            }
            id={"search"}
            value={search}
            onChange={onChangeSearch}
            placeholder={"Поиск"}
          />
        </div>
      </div>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
      <table className={"min-w-full h-full"}>
        <thead>
          <tr
            className={
              "text-amber-500 bg-[#242424] font-bold h-10 rounded-t-full rounded-tl-full rounded-tr-full border-b border-b-slate-500"
            }
          >
            <th className={"pl-4 pr-4"}>ID</th>
            <th className={"pl-4 pr-4"}>STEAM ID</th>
            <th className={"pl-4 pr-4"}>Имя</th>
            <th className={"pl-4 pr-4"}>Дата регистрации</th>
            <th className={"pl-4 pr-4"}>Баланс</th>
            <th className={"pl-4 pr-4"}>Управление</th>
          </tr>
        </thead>
        <tbody>
          {users.items.map((user) => (
            <tr
              key={user.id}
              className={
                "text-white odd:bg-[#1C1C1C] even:bg-[#242424] text-center h-10 border-b border-b-slate-500 last:border-b-0 last:rounded-bl-full last:rounded-br-full last:rounded-b-full"
              }
            >
              <td className={"pl-4 pr-4"}>{user.id}</td>
              <td className={"pl-4 pr-4"}>
                <Link
                  target={"_blank"}
                  to={`https://steamcommunity.com/profiles/${user.steamid}`}
                >
                  {user.steamid}{" "}
                </Link>
              </td>
              <td
                className={
                  "pl-4 pr-4 flex justify-center items-center text-center content-center w-full h-full"
                }
              >
                <img
                  className={"w-6 h-6 mr-3 rounded-full"}
                  src={user.imageUrl}
                  alt={"Avatar"}
                />
                <span>{user.name}</span>
              </td>
              <td className={"pl-4 pr-4"}>
                {new Date(user.createAt).toLocaleString("ru-RU")}
              </td>
              <td className={"pl-4 pr-4"}>{user.balance}</td>
              <td className={"pl-4 pr-4"}>
                <button
                  onClick={() => {
                    setOpenModal(true);
                    setCurrentUser(user);
                  }}
                  className={"rounded-full bg-emerald-600 w-7 h-7 "}
                >
                  <EditIcon className={"scale-90 text-white select-none"} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        onClose={handleClose}
        open={openModal}
        className="flex items-center justify-center"
      >
        <div className="w-96 relative">
          <button
            onClick={() => setOpenModal(false)}
            className="absolute right-4 top-4"
          >
            ✖
          </button>
          {currentUser && (
            <Formik
              onSubmit={async (
                values: IUserAdmin,
                { setSubmitting }: FormikHelpers<IUserAdmin>,
              ) => {
                try {
                  console.log(values);
                  const { id, balance, ...otherValues } = values;
                  await axios.patch(`/user/${id}`, {
                    balance: +balance,
                    ...otherValues,
                  });
                  showNotification("Удачно", "success");
                  fetchUsers();
                  setSubmitting(false);
                  setOpenModal(false);
                } catch (e: any) {
                  showNotification(e?.message, "error");
                  setOpenModal(false);
                }
              }}
              initialValues={currentUser}
            >
              <Form className="flex flex-col p-10 bg-[#242424] rounded-2xl text-amber-500 gap-3">
                <label htmlFor="name">Имя</label>
                <Field
                  readOnly
                  id="name"
                  name="name"
                  placeholder="name"
                  className="text-black p-1 rounded-2xl outline-none"
                />
                <label htmlFor="balance">Баланс</label>
                <Field
                  id="balance"
                  name="balance"
                  placeholder="balance"
                  autocomplete="off"
                  className="text-black p-1 rounded-2xl outline-none"
                />
                <label htmlFor="isAdmin">
                  <Field
                    type="checkbox"
                    id="isAdmin"
                    name="isAdmin"
                    placeholder="isAdmin"
                    className="text-black p-1 ml-2"
                  />{" "}
                  Права администратора
                </label>
                <label htmlFor="isModerator">
                  <Field
                    type="checkbox"
                    id="isModerator"
                    name="isModerator"
                    placeholder="isAdmin"
                    className="text-black p-1 ml-2"
                  />{" "}
                  Права модератора
                </label>
                <button
                  className="cursor-pointer text-l px-10 py-2 rounded text-black font-semibold bg-amber-500 mt-3"
                  type="submit"
                >
                  Submit
                </button>
              </Form>
            </Formik>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default Users;
