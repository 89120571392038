import { useEffect } from "react";
import { useAppSelector } from "../app/hooks";
import {
  getOpenItem,
  selectCategory,
  serverSelect,
} from "../app/filter/filterSlice";
import qs from "qs";
import { useNavigate } from "react-router-dom";

function useFilter() {
  const categoryId = useAppSelector(selectCategory);
  const serverId = useAppSelector(serverSelect);
  const itemOpen = useAppSelector(getOpenItem);
  const navigation = useNavigate();
  useEffect(() => {
    let queryString = qs.stringify({
      category: categoryId,
      server: serverId,
    });
    if (itemOpen) {
      queryString = qs.stringify({
        category: categoryId,
        server: serverId,
        item: itemOpen,
      });
    }

    navigation(`/?${queryString}`);
  }, [categoryId, serverId, itemOpen]);
  return;
}

export default useFilter;
