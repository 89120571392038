import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "../../api/axios";
export interface Category {
  id: number;
  name: string;
}

export interface CategorySlice {
  items: Category[];
  status: "loaded" | "loading" | "failed";
}

const initialState: CategorySlice = {
  items: [],
  status: "loading",
};
export const getAllCategory = createAsyncThunk(
  "category/getAllCategory",
  async () => {
    const response = await axios.get("/category/");
    return response.data;
  },
);

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllCategory.fulfilled, (state, action) => {
        state.status = "loaded";
        state.items = action.payload;
      })
      .addCase(getAllCategory.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = categorySlice.actions;
export const getCategoryes = (state: RootState) => state.category.items;

export default categorySlice.reducer;
