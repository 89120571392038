import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./features/pages/home/Home";
import useDocumentTitle from "./hooks/useDocumentTitle";
import Maze from "./features/pages/maze/Maze";
import Dino from "./features/pages/dino/Dino";
import { fetchSession } from "./app/user/userSlice";
import { useAppDispatch } from "./app/hooks";
import Payment from "./features/pages/payment/Payment";
import Chat from "./features/pages/chat/Chat";
import Admin from "./features/pages/admin/Admin";
import { Profile } from "./features/pages/profile/Profile";

function App() {
  useDocumentTitle("MRAZ STORE", true);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchSession());
  }, []);
  return (
    <>
      {/*<SnowEffect />*/}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/maze" element={<Maze />} />
        <Route path="/payment/:id" element={<Payment />} />
        <Route path="/dino" element={<Dino />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/admin/*" element={<Admin />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </>
  );
}

export default App;
