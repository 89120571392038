import React from "react";
import Server from "./Server";
import { useAppSelector } from "../../../app/hooks";
import { getServers } from "../../../app/servers/serverSlice";

function Servers() {
  const servers = useAppSelector(getServers);
  return (
    <div className="border border-[#1B1B1B] mb-3 xl:mb-0 w-[254px] min-h-[220px] bg-zinc-900 bg-opacity-50 rounded-xl flex justify-start content-center items-center flex-col">
      <span className={"font-bold text-center text-white mt-2"}>СЕРВЕРА</span>
      {servers.map((server) => (
        <Server key={server.id} {...server} />
      ))}
    </div>
  );
}

export default Servers;
