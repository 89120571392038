import React, { useEffect, useState } from "react";
import Loading from "../../../../../components/loading/Loading";
import axios from "../../../../../../api/axios";
import { IPromoAdminResponse } from "../../../../../../api/admin.entity";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Modal } from "@mui/material";
import { Field, Form, Formik } from "formik";

interface MyFormValues {
  firstName: string;
}

function Promos() {
  const [items, setItems] = useState<IPromoAdminResponse>();
  const [openModal, setOpenModal] = useState(false);
  const initialValues: MyFormValues = { firstName: "" };
  useEffect(() => {
    const fetchUsers = async () => {
      const { data } = await axios.get<IPromoAdminResponse>(
        "/admin/allPromo?page=1&limit=10",
      );
      setItems(data);
    };
    fetchUsers();
  }, []);
  if (!items) {
    return <Loading />;
  }
  return (
    <div className={"w-full h-full"}>
      <table className={"min-w-full h-full"}>
        <thead>
          <tr
            className={
              "text-amber-500 font-bold bg-[#242424] h-10 rounded-t-full rounded-tl-full rounded-tr-full border-b border-b-slate-500"
            }
          >
            <th className={"pl-4 pr-4"}>ID</th>
            <th className={"pl-4 pr-4"}>Код</th>
            <th className={"pl-4 pr-4"}>Активаций</th>
            <th className={"pl-4 pr-4"}>Баланс</th>
            <th className={"pl-4 pr-4"}>Предмет</th>
            <th className={"pl-4 pr-4"}>Скидка</th>
            <th className={"pl-4 pr-4"}>Создатель</th>
            <th className={"pl-4 pr-4"}>Макс. Активаций</th>
            <th className={"pl-4 pr-4"}>Дата создания</th>
            <th className={"pl-4 pr-4"}>Дата окончания</th>
            <th className={"pl-4 pr-4"}>Управление</th>
          </tr>
        </thead>
        <tbody>
          {items.items.map((item) => (
            <>
              <tr
                key={item.id}
                className={
                  (new Date(item.deleteAt) > new Date()
                    ? "odd:bg-[#1C1C1C] even:bg-[#242424] "
                    : "bg-[#6C0000] opacity-30 ") +
                  "text-white bg-[#1C1C1C] text-center h-10 border-b border-b-slate-500 last:border-b-0 last:rounded-bl-full last:rounded-br-full last:rounded-b-full"
                }
              >
                <td className={"pl-4 pr-4"}>{item.id}</td>
                <td className={"pl-4 pr-4"}>{item.promo}</td>
                <td className={"pl-4 pr-4"}>{item.userCount}</td>
                <td className={"pl-4 pr-4"}>{item.giveBalance}</td>
                <td className={"pl-4 pr-4"}>
                  {!item.itemName ? (
                    <span>Нету</span>
                  ) : (
                    <>
                      {" "}
                      <span className={"uppercase mr-5"}>{item.itemName}</span>
                      <span className={"uppercase"}>x{item.itemCount}</span>
                    </>
                  )}
                </td>
                <td className={"pl-4 pr-4"}>{item.personalSale}</td>
                <td className={"pl-4 pr-4"}>{item.creator}</td>
                <td className={"pl-4 pr-4"}>{item.maxActive}</td>
                <td className={"pl-4 pr-4"}>
                  {new Date(item.createAt).toLocaleString("ru-RU")}
                </td>{" "}
                <td className={"pl-4 pr-4"}>
                  {new Date(item.deleteAt).toLocaleString("ru-RU")}
                </td>
                <td className={"pl-4 pr-4"}>
                  <button
                    onClick={() => setOpenModal(true)}
                    className={"rounded-full bg-emerald-600 w-7 h-7 "}
                  >
                    <EditIcon className={"scale-90 text-white select-none"} />
                  </button>
                </td>
              </tr>
              <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
              >
                <Box className="w-96 bg-">
                  {" "}
                  //sx
                  <Formik
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                      console.log({ values, actions });
                      alert(JSON.stringify(values, null, 2));
                      actions.setSubmitting(false);
                    }}
                  >
                    <Form>
                      <label htmlFor="firstName">First Name</label>
                      <Field
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                      />
                      <button type="submit">Submit</button>
                    </Form>
                  </Formik>
                </Box>
              </Modal>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Promos;
