import React, { useEffect, useState } from "react";
import Loading from "../../../../../components/loading/Loading";
import axios from "../../../../../../api/axios";
import { ITransactionAdminResponse } from "../../../../../../api/admin.entity";
import EditIcon from "@mui/icons-material/Edit";

function Transactions() {
  const [items, setItems] = useState<ITransactionAdminResponse>();
  useEffect(() => {
    const fetchUsers = async () => {
      const { data } = await axios.get<ITransactionAdminResponse>(
        "/admin/allTransaction?page=1&limit=10",
      );
      setItems(data);
    };
    fetchUsers();
  }, []);
  if (!items) {
    return <Loading />;
  }
  return (
    <div className={"w-full h-full"}>
      <table className={"min-w-full h-full"}>
        <thead>
          <tr
            className={
              "text-amber-500 font-bold bg-[#242424] h-10 rounded-t-full rounded-tl-full rounded-tr-full border-b border-b-slate-500"
            }
          >
            <th className={"pl-4 pr-4"}>ID</th>
            <th className={"pl-4 pr-4"}>ID Транзакции</th>
            <th className={"pl-4 pr-4"}>Пользователь</th>
            <th className={"pl-4 pr-4"}>Сумма</th>
            <th className={"pl-4 pr-4"}>Система</th>
            <th className={"pl-4 pr-4"}>Статус</th>
            <th className={"pl-4 pr-4"}>Дата создания</th>
            <th className={"pl-4 pr-4"}>Управление</th>
          </tr>
        </thead>
        <tbody>
          {items.items.map((item) => (
            <tr
              key={item.id}
              className={
                "odd:bg-[#1C1C1C] even:bg-[#242424] " +
                "text-white bg-[#1C1C1C] text-center h-10 border-b border-b-slate-500 last:border-b-0 last:rounded-bl-full last:rounded-br-full last:rounded-b-full"
              }
            >
              <td className={"pl-4 pr-4"}>{item.id}</td>
              <td className={"pl-4 pr-4"}>{item.transactionId}</td>
              <td className={"pl-4 pr-4"}>{item.user}</td>
              <td className={"pl-4 pr-4"}>{item.amount}</td>
              <td className={"pl-4 pr-4"}>{item.system}</td>
              <td className={"pl-4 pr-4"}>{item.status}</td>
              <td className={"pl-4 pr-4"}>
                {new Date(item.createAt).toLocaleString("ru-RU")}
              </td>
              <td className={"pl-4 pr-4"}>
                <button className={"rounded-full bg-emerald-600 w-7 h-7 "}>
                  <EditIcon className={"scale-90 text-white select-none"} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Transactions;
