// src/index.tsx
import React, { useState, useEffect, useCallback } from "react";

const rows = 25;
const cols = 25;

const generateRandomMaze = () => {
  const maze = [];
  for (let i = 0; i < rows; i++) {
    const row = [];
    for (let j = 0; j < cols; j++) {
      row.push(Math.random() > 0.7 ? 1 : 0); // 1 represents a wall, 0 represents an open path
    }
    maze.push(row);
  }
  return maze;
};

const findRandomEmptyCell = (maze: number[][]) => {
  while (true) {
    const row = Math.floor(Math.random() * rows);
    const col = Math.floor(Math.random() * cols);
    if (maze[row][col] === 0) {
      return { row, col };
    }
  }
};

const Maze = () => {
  const [maze, setMaze] = useState<number[][]>(generateRandomMaze);
  const [playerPosition, setPlayerPosition] = useState(
    findRandomEmptyCell(maze),
  );
  const [exitPosition, setExitPosition] = useState(findRandomEmptyCell(maze));
  const movePlayer = useCallback(
    (direction: string) => {
      const { row, col } = playerPosition;
      let newRow = row;
      let newCol = col;

      switch (direction) {
        case "w":
          newRow = Math.max(0, row - 1);
          break;
        case "a":
          newCol = Math.max(0, col - 1);
          break;
        case "s":
          newRow = Math.min(rows - 1, row + 1);
          break;
        case "d":
          newCol = Math.min(cols - 1, col + 1);
          break;
        default:
          return;
      }

      if (maze[newRow][newCol] === 0) {
        setPlayerPosition({ row: newRow, col: newCol });
      }

      if (newRow === exitPosition.row && newCol === exitPosition.col) {
        alert("Congratulations! You reached the exit.");
        setMaze(generateRandomMaze());
        setPlayerPosition(findRandomEmptyCell(maze));
        setExitPosition(findRandomEmptyCell(maze));
      }
    },
    [maze, playerPosition, exitPosition],
  );
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const { key } = event;
      movePlayer(key);
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [movePlayer]);

  return (
    <div className="flex flex-col items-center mt-8">
      {maze.map((row, rowIndex) => (
        <div key={rowIndex} className="flex">
          {row.map((cell, colIndex) => (
            <div
              key={colIndex}
              className={`w-8 h-8 border ${
                cell === 1 ? "bg-gray-700" : "bg-white"
              } border-gray-500`}
            >
              {playerPosition.row === rowIndex &&
                playerPosition.col === colIndex && (
                  <img
                    className="w-full h-full object-cover"
                    src="hero.png"
                    alt="Player"
                  />
                )}
              {exitPosition.row === rowIndex &&
                exitPosition.col === colIndex && (
                  <img
                    className="w-full h-full object-cover"
                    src="https://via.placeholder.com/20"
                    alt="Exit"
                  />
                )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Maze;
