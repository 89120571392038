import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "../../api/axios";
export interface ServerInfo {
  status: "online" | "offline";
  name?: string;
  categoryName?: string;
  id?: number; // assuming 'id' is optional
  address?: string; // assuming 'address' is optional
  players?: number; // assuming 'players' is a number
  quene?: number; // assuming 'quene' is a number
  maxplayers?: number; // assuming 'maxplayers' is a number
  isCategory?: boolean;
}
export interface ServersSlice {
  servers: ServerInfo[];
  status: "loaded" | "loading" | "failed";
}

const initialState: ServersSlice = {
  servers: [],
  status: "loading",
};
export const getAllServers = createAsyncThunk(
  "servers/getAllServers",
  async () => {
    const response = await axios.get("/server/");
    return response.data;
  },
);

export const serversSlice = createSlice({
  name: "servers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllServers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllServers.fulfilled, (state, action) => {
        state.status = "loaded";
        state.servers = action.payload;
      })
      .addCase(getAllServers.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = serversSlice.actions;
export const getServers = (state: RootState) => state.servers.servers;
export const getServerStatus = (state: RootState) => state.servers.status;

export default serversSlice.reducer;
