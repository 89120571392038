import React from "react";
import ProgressBar from "./ProgressBar";
import { Link } from "react-router-dom";
import { ServerInfo } from "../../../app/servers/serverSlice";

function Server({
  status,
  name,
  players,
  maxplayers,
  quene,
  address,
}: ServerInfo) {
  const handleCopyClick = () => {
    // Create a temporary textarea to copy the text to the clipboard
    const textarea = document.createElement("textarea");
    textarea.value = address ?? "";
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();
    document.execCommand("copy");

    // Remove the temporary textarea
    document.body.removeChild(textarea);
    alert("Коннект скопирован!");
  };
  if (status === "offline") {
    return (
      <div className="w-[221px] h-14 relative mb-4 ">
        <div className={"flex justify-between content-between items-center "}>
          <div className="text-white text-base font-normal font-['Roboto']">
            {name}
          </div>
          <div className={"flex justify-center content-center items-center"}>
            <div onClick={handleCopyClick} className="Copy w-8 h-8">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.179 12.48H11.52C12.0502 12.48 12.48 12.0502 12.48 11.52L12.48 11.2218L12.48 11.2164C12.4788 10.9943 12.5007 10.7744 12.5444 10.56C12.6675 9.95557 12.964 9.39465 13.4044 8.94939C14.0003 8.34671 14.8112 8.00526 15.6588 8H20.8212C21.6688 8.00526 22.4797 8.34671 23.0757 8.94939C23.6723 9.55259 24.0046 10.3681 24 11.2164V11.2218V16.2982L24.0001 16.3036C24.0047 17.1519 23.6723 17.9674 23.0757 18.5706C22.6269 19.0244 22.0563 19.33 21.44 19.4556C21.2371 19.497 21.0292 19.5188 20.819 19.52H20.48C19.9829 19.52 19.5741 19.8978 19.5249 20.3818C19.5216 20.4141 19.52 20.4469 19.52 20.48V20.7782L19.5201 20.7836L19.5689 20.7833C19.6958 21.1649 20.0558 21.44 20.48 21.44H20.8243H20.8297C21.0227 21.4389 21.2146 21.427 21.4044 21.4044C22.5497 21.2686 23.6219 20.7488 24.4408 19.9208C25.3954 18.9554 25.9275 17.6504 25.92 16.2929V11.2271C25.9275 9.86952 25.3954 8.56456 24.4408 7.59928C23.4862 6.63401 22.1872 6.0875 20.8297 6.08H20.8243L15.6558 6.07999L15.6504 6.08001C14.2928 6.08751 12.9939 6.63401 12.0392 7.59928C11.2338 8.41371 10.7291 9.46994 10.5956 10.5956C10.571 10.8032 10.559 11.0132 10.56 11.2245V11.52C10.56 11.9288 10.8156 12.278 11.1757 12.4164V12.48H11.179ZM15.6504 6.08001L15.6558 7.04V7.05155L15.6504 6.08001ZM20.8297 6.08L20.8243 7.05171V7.04L20.8297 6.08ZM16.3443 24.9485L16.339 24H16.3443V24.9485ZM16.3443 24.96L16.3497 25.92H16.3443V24.96ZM11.181 24H11.1757V24.96L11.181 24ZM11.1704 25.92H11.1757L11.1757 24.9612L11.1704 25.92ZM8.00002 20.7795V20.7845L7.04002 20.7795H8.00002ZM7.04002 20.7795H6.08002V20.7771L6.08004 20.7745L7.04002 20.7795ZM8.00004 15.7018L8.00001 15.6964L7.04002 15.7018H6.08002V15.7047L6.08004 15.7071L7.04002 15.7018H8.00004ZM16.3444 12.48H16.3411L16.339 12.48L16.3444 11.5084V11.52V12.48ZM16.3444 11.5084V10.56H16.3497L16.3444 11.5084ZM19.5201 15.7018V15.6964L20.4801 15.7018H19.5201ZM20.4801 15.7018H21.4401L21.44 15.7071L20.4801 15.7018Z"
                  fill="#FF9900"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.179 12.48H11.52C12.0502 12.48 12.48 12.0502 12.48 11.52L12.48 11.2218L12.48 11.2164C12.4788 10.9943 12.5007 10.7744 12.5444 10.56C12.6675 9.95557 12.964 9.39465 13.4044 8.94939C14.0003 8.34671 14.8112 8.00526 15.6588 8H20.8212C21.6688 8.00526 22.4797 8.34671 23.0757 8.94939C23.6723 9.55259 24.0046 10.3681 24 11.2164V11.2218V16.2982L24.0001 16.3036C24.0047 17.1519 23.6723 17.9674 23.0757 18.5706C22.6269 19.0244 22.0563 19.33 21.44 19.4556C21.2371 19.497 21.0292 19.5188 20.819 19.52H20.48C19.9829 19.52 19.5741 19.8978 19.5249 20.3818C19.5216 20.4141 19.52 20.4469 19.52 20.48V20.7782L19.5201 20.7836L19.5689 20.7833C19.6958 21.1649 20.0558 21.44 20.48 21.44H20.8243H20.8297C21.0227 21.4389 21.2146 21.427 21.4044 21.4044C22.5497 21.2686 23.6219 20.7488 24.4408 19.9208C25.3954 18.9554 25.9275 17.6504 25.92 16.2929V11.2271C25.9275 9.86952 25.3954 8.56456 24.4408 7.59928C23.4862 6.63401 22.1872 6.0875 20.8297 6.08H20.8243L15.6558 6.07999L15.6504 6.08001C14.2928 6.08751 12.9939 6.63401 12.0392 7.59928C11.2338 8.41371 10.7291 9.46994 10.5956 10.5956C10.571 10.8032 10.559 11.0132 10.56 11.2245V11.52C10.56 11.9288 10.8156 12.278 11.1757 12.4164V12.48H11.179ZM15.6504 6.08001L15.6558 7.04V7.05155L15.6504 6.08001ZM20.8297 6.08L20.8243 7.05171V7.04L20.8297 6.08ZM16.3443 24.9485L16.339 24H16.3443V24.9485ZM16.3443 24.96L16.3497 25.92H16.3443V24.96ZM11.181 24H11.1757V24.96L11.181 24ZM11.1704 25.92H11.1757L11.1757 24.9612L11.1704 25.92ZM8.00002 20.7795V20.7845L7.04002 20.7795H8.00002ZM7.04002 20.7795H6.08002V20.7771L6.08004 20.7745L7.04002 20.7795ZM8.00004 15.7018L8.00001 15.6964L7.04002 15.7018H6.08002V15.7047L6.08004 15.7071L7.04002 15.7018H8.00004ZM16.3444 12.48H16.3411L16.339 12.48L16.3444 11.5084V11.52V12.48ZM16.3444 11.5084V10.56H16.3497L16.3444 11.5084ZM19.5201 15.7018V15.6964L20.4801 15.7018H19.5201ZM20.4801 15.7018H21.4401L21.44 15.7071L20.4801 15.7018Z"
                  fill="black"
                  fillOpacity="0.2"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.179 12.48H11.52C12.0502 12.48 12.48 12.0502 12.48 11.52L12.48 11.2218L12.48 11.2164C12.4788 10.9943 12.5007 10.7744 12.5444 10.56H11.52L11.1757 10.56L11.1704 10.56C10.9773 10.5611 10.7855 10.573 10.5956 10.5956C10.571 10.8032 10.559 11.0132 10.56 11.2245V11.52C10.56 11.9288 10.8156 12.278 11.1757 12.4164V12.48H11.179ZM15.661 7.99999L15.6588 8H15.6558V7.05155L15.661 7.99999ZM15.6558 7.04L15.6504 6.08001L15.6558 6.07999V7.04ZM20.8243 6.08V7.04L20.8297 6.08H20.8243ZM20.8212 8H20.8243V7.05171L20.819 7.99999L20.8212 8ZM25.92 11.2271V11.2218H24.96L24 11.2164V11.2218H24.96L25.92 11.2271ZM24.0001 16.3036L24 16.2982H24.96L24.0001 16.3036ZM24.96 16.2982L25.92 16.2929V16.2982H24.96ZM20.819 19.52C21.0292 19.5188 21.2371 19.497 21.44 19.4556L21.44 20.48V20.7729C21.4411 20.985 21.4291 21.1959 21.4044 21.4044C21.2146 21.427 21.0227 21.4389 20.8297 21.44H20.8243H20.48C20.0558 21.44 19.6958 21.1649 19.5689 20.7833L19.5201 20.7836L19.52 20.7782V20.48C19.52 20.4469 19.5216 20.4141 19.5249 20.3818C19.5741 19.8978 19.9829 19.52 20.48 19.52H20.819ZM16.3443 24.9485L16.339 24H16.3443V24.9485ZM16.3443 24.96L16.3497 25.92H16.3443V24.96ZM11.181 24H11.1757V24.96L11.181 24ZM11.1704 25.92H11.1757L11.1757 24.9612L11.1704 25.92ZM8.00002 20.7795V20.7845L7.04002 20.7795H8.00002ZM7.04002 20.7795H6.08002V20.7771L6.08004 20.7745L7.04002 20.7795ZM8.00004 15.7018L8.00001 15.6964L7.04002 15.7018H6.08002V15.7047L6.08004 15.7071L7.04002 15.7018H8.00004ZM16.3444 12.48H16.3411L16.339 12.48L16.3444 11.5084V11.52V12.48ZM16.3497 10.56L16.3444 11.5084V10.56H16.3497ZM19.5201 15.7018V15.6964L20.4801 15.7018H19.5201ZM21.44 15.7071L20.4801 15.7018H21.4401L21.44 15.7071Z"
                  fill="#FF9900"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.179 12.48H11.52C12.0502 12.48 12.48 12.0502 12.48 11.52L12.48 11.2218L12.48 11.2164C12.4788 10.9943 12.5007 10.7744 12.5444 10.56H11.52L11.1757 10.56L11.1704 10.56C10.9773 10.5611 10.7855 10.573 10.5956 10.5956C10.571 10.8032 10.559 11.0132 10.56 11.2245V11.52C10.56 11.9288 10.8156 12.278 11.1757 12.4164V12.48H11.179ZM15.661 7.99999L15.6588 8H15.6558V7.05155L15.661 7.99999ZM15.6558 7.04L15.6504 6.08001L15.6558 6.07999V7.04ZM20.8243 6.08V7.04L20.8297 6.08H20.8243ZM20.8212 8H20.8243V7.05171L20.819 7.99999L20.8212 8ZM25.92 11.2271V11.2218H24.96L24 11.2164V11.2218H24.96L25.92 11.2271ZM24.0001 16.3036L24 16.2982H24.96L24.0001 16.3036ZM24.96 16.2982L25.92 16.2929V16.2982H24.96ZM20.819 19.52C21.0292 19.5188 21.2371 19.497 21.44 19.4556L21.44 20.48V20.7729C21.4411 20.985 21.4291 21.1959 21.4044 21.4044C21.2146 21.427 21.0227 21.4389 20.8297 21.44H20.8243H20.48C20.0558 21.44 19.6958 21.1649 19.5689 20.7833L19.5201 20.7836L19.52 20.7782V20.48C19.52 20.4469 19.5216 20.4141 19.5249 20.3818C19.5741 19.8978 19.9829 19.52 20.48 19.52H20.819ZM16.3443 24.9485L16.339 24H16.3443V24.9485ZM16.3443 24.96L16.3497 25.92H16.3443V24.96ZM11.181 24H11.1757V24.96L11.181 24ZM11.1704 25.92H11.1757L11.1757 24.9612L11.1704 25.92ZM8.00002 20.7795V20.7845L7.04002 20.7795H8.00002ZM7.04002 20.7795H6.08002V20.7771L6.08004 20.7745L7.04002 20.7795ZM8.00004 15.7018L8.00001 15.6964L7.04002 15.7018H6.08002V15.7047L6.08004 15.7071L7.04002 15.7018H8.00004ZM16.3444 12.48H16.3411L16.339 12.48L16.3444 11.5084V11.52V12.48ZM16.3497 10.56L16.3444 11.5084V10.56H16.3497ZM19.5201 15.7018V15.6964L20.4801 15.7018H19.5201ZM21.44 15.7071L20.4801 15.7018H21.4401L21.44 15.7071Z"
                  fill="white"
                />
                <path
                  d="M8.24178 12.7543L8.92435 13.4294C9.52043 12.8267 10.3313 12.4852 11.179 12.48H11.1757V12.4164C10.8156 12.278 10.56 11.9288 10.56 11.52V11.2245C10.559 11.0132 10.571 10.8032 10.5956 10.5956C9.45032 10.7314 8.37807 11.2513 7.5592 12.0793L8.24178 12.7543Z"
                  fill="#FF9900"
                />
                <path
                  d="M8.24178 12.7543L8.92435 13.4294C9.52043 12.8267 10.3313 12.4852 11.179 12.48H11.1757V12.4164C10.8156 12.278 10.56 11.9288 10.56 11.52V11.2245C10.559 11.0132 10.571 10.8032 10.5956 10.5956C9.45032 10.7314 8.37807 11.2513 7.5592 12.0793L8.24178 12.7543Z"
                  fill="white"
                />
                <path
                  d="M8.92435 13.4294L8.24178 12.7543L7.5592 12.0793C6.60513 13.044 6.07313 14.348 6.08002 15.7047V15.7018H7.04002L8.00001 15.6964C7.9953 14.8481 8.32779 14.0326 8.92435 13.4294Z"
                  fill="#FF9900"
                />
                <path
                  d="M8.92435 13.4294L8.24178 12.7543L7.5592 12.0793C6.60513 13.044 6.07313 14.348 6.08002 15.7047V15.7018H7.04002L8.00001 15.6964C7.9953 14.8481 8.32779 14.0326 8.92435 13.4294Z"
                  fill="white"
                />
                <path
                  d="M8.00004 15.7018H7.04002L6.08004 15.7071L6.08002 15.7047V20.7771L6.08004 20.7745L7.04002 20.7795H8.00002L8.00004 15.7018Z"
                  fill="#FF9900"
                />
                <path
                  d="M8.00004 15.7018H7.04002L6.08004 15.7071L6.08002 15.7047V20.7771L6.08004 20.7745L7.04002 20.7795H8.00002L8.00004 15.7018Z"
                  fill="white"
                />
                <path
                  d="M7.04002 20.7795H6.08002V20.7771C6.06658 23.6023 8.34486 25.9044 11.1704 25.92L11.1757 24.9612L11.1757 24.96V24H11.181C9.41478 23.9903 7.99076 22.5508 8.00002 20.7845L7.04002 20.7795Z"
                  fill="#FF9900"
                />
                <path
                  d="M7.04002 20.7795H6.08002V20.7771C6.06658 23.6023 8.34486 25.9044 11.1704 25.92L11.1757 24.9612L11.1757 24.96V24H11.181C9.41478 23.9903 7.99076 22.5508 8.00002 20.7845L7.04002 20.7795Z"
                  fill="white"
                />
                <path
                  d="M16.3443 25.92V24.96V24.9485L16.339 24H11.181L11.1757 24.96L11.1757 24.9612L11.1757 25.92H16.3443Z"
                  fill="#FF9900"
                />
                <path
                  d="M16.3443 25.92V24.96V24.9485L16.339 24H11.181L11.1757 24.96L11.1757 24.9612L11.1757 25.92H16.3443Z"
                  fill="white"
                />
                <path
                  d="M16.339 24H16.3443V24.9485L16.3497 25.92C17.7072 25.9124 19.0062 25.366 19.9608 24.4008L18.5957 23.0506C17.9992 23.6538 17.1874 23.9953 16.339 24Z"
                  fill="#FF9900"
                />
                <path
                  d="M16.339 24H16.3443V24.9485L16.3497 25.92C17.7072 25.9124 19.0062 25.366 19.9608 24.4008L18.5957 23.0506C17.9992 23.6538 17.1874 23.9953 16.339 24Z"
                  fill="white"
                />
                <path
                  d="M20.8297 21.44H20.8243H20.48C20.0558 21.44 19.6958 21.1649 19.5689 20.7833L19.5201 20.7836C19.5247 21.6319 19.1923 22.4474 18.5957 23.0506L19.9608 24.4008C20.7662 23.5863 21.2709 22.53 21.4044 21.4044C21.2146 21.427 21.0227 21.4389 20.8297 21.44Z"
                  fill="#FF9900"
                />
                <path
                  d="M20.8297 21.44H20.8243H20.48C20.0558 21.44 19.6958 21.1649 19.5689 20.7833L19.5201 20.7836C19.5247 21.6319 19.1923 22.4474 18.5957 23.0506L19.9608 24.4008C20.7662 23.5863 21.2709 22.53 21.4044 21.4044C21.2146 21.427 21.0227 21.4389 20.8297 21.44Z"
                  fill="white"
                />
                <path
                  d="M20.4801 15.7018H19.5201L19.52 20.48C19.52 20.4469 19.5216 20.4141 19.5249 20.3818C19.5741 19.8978 19.9829 19.52 20.48 19.52H20.819C21.0292 19.5188 21.2371 19.497 21.44 19.4556L21.4401 15.7018L21.44 15.7071L20.4801 15.7018Z"
                  fill="#FF9900"
                />
                <path
                  d="M20.4801 15.7018H19.5201L19.52 20.48C19.52 20.4469 19.5216 20.4141 19.5249 20.3818C19.5741 19.8978 19.9829 19.52 20.48 19.52H20.819C21.0292 19.5188 21.2371 19.497 21.44 19.4556L21.4401 15.7018L21.44 15.7071L20.4801 15.7018Z"
                  fill="white"
                />
                <path
                  d="M20.4801 15.7018H21.4401C21.442 15.2701 21.3893 14.8437 21.2859 14.4319C21.2654 14.3503 21.2429 14.2693 21.2185 14.1889C21.1888 14.0913 21.1562 13.9946 21.1207 13.899C21.0763 13.7793 21.0275 13.6613 20.9743 13.5453C20.871 13.32 20.7513 13.102 20.6159 12.8933C20.5573 12.803 20.4958 12.7144 20.4314 12.6277C20.2885 12.4351 20.1314 12.2518 19.9608 12.0793L18.5957 13.4294C19.1923 14.0326 19.5247 14.8481 19.5201 15.6964L20.4801 15.7018Z"
                  fill="#FF9900"
                />
                <path
                  d="M20.4801 15.7018H21.4401C21.442 15.2701 21.3893 14.8437 21.2859 14.4319C21.2654 14.3503 21.2429 14.2693 21.2185 14.1889C21.1888 14.0913 21.1562 13.9946 21.1207 13.899C21.0763 13.7793 21.0275 13.6613 20.9743 13.5453C20.871 13.32 20.7513 13.102 20.6159 12.8933C20.5573 12.803 20.4958 12.7144 20.4314 12.6277C20.2885 12.4351 20.1314 12.2518 19.9608 12.0793L18.5957 13.4294C19.1923 14.0326 19.5247 14.8481 19.5201 15.6964L20.4801 15.7018Z"
                  fill="white"
                />
                <path
                  d="M18.5957 13.4294L19.9608 12.0793C19.0062 11.114 17.7072 10.5675 16.3497 10.56L16.3444 11.5084V11.52V12.48H16.3411C17.1887 12.4852 17.9997 12.8267 18.5957 13.4294Z"
                  fill="#FF9900"
                />
                <path
                  d="M18.5957 13.4294L19.9608 12.0793C19.0062 11.114 17.7072 10.5675 16.3497 10.56L16.3444 11.5084V11.52V12.48H16.3411C17.1887 12.4852 17.9997 12.8267 18.5957 13.4294Z"
                  fill="white"
                />
                <path
                  d="M12.48 11.52C12.48 12.0502 12.0502 12.48 11.52 12.48H16.3411L16.339 12.48L16.3444 11.5084V10.56H12.5444C12.5007 10.7744 12.4788 10.9943 12.48 11.2164L12.48 11.2218L12.48 11.52Z"
                  fill="#FF9900"
                />
                <path
                  d="M12.48 11.52C12.48 12.0502 12.0502 12.48 11.52 12.48H16.3411L16.339 12.48L16.3444 11.5084V10.56H12.5444C12.5007 10.7744 12.4788 10.9943 12.48 11.2164L12.48 11.2218L12.48 11.52Z"
                  fill="white"
                />
              </svg>
            </div>
            <Link to={`steam://${address}`} className="w-[17px] h-[18px]">
              <div className="w-[17px] h-[18px]bg-amber-500 rounded-full">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="8.79602"
                    cy="8.79602"
                    r="8.79602"
                    fill="#FF9900"
                  />
                  <path
                    d="M13.1941 9.23582L5.93737 13.4255L5.93738 5.04615L13.1941 9.23582Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className="w-[221px] h-[27px] flex justify-center content-center items-center bg-white bg-opacity-60 rounded-[15px]">
          <ProgressBar online={100} maxonline={100} query={0} />
          <span
            className={
              "absolute mt-8 ml-4 w-full h-full text-left text-black font-bold"
            }
          >
            {players}/{maxplayers}
          </span>
        </div>
      </div>
    );
  }
  return (
    <div className="w-[221px] h-14 relative mb-4 ">
      <div className={"flex justify-between content-between items-center "}>
        <div className="text-white text-base font-normal font-['Roboto']">
          {name}
        </div>
        <div className={"flex justify-center content-center items-center"}>
          <div onClick={handleCopyClick} className="Copy w-8 h-8">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.179 12.48H11.52C12.0502 12.48 12.48 12.0502 12.48 11.52L12.48 11.2218L12.48 11.2164C12.4788 10.9943 12.5007 10.7744 12.5444 10.56C12.6675 9.95557 12.964 9.39465 13.4044 8.94939C14.0003 8.34671 14.8112 8.00526 15.6588 8H20.8212C21.6688 8.00526 22.4797 8.34671 23.0757 8.94939C23.6723 9.55259 24.0046 10.3681 24 11.2164V11.2218V16.2982L24.0001 16.3036C24.0047 17.1519 23.6723 17.9674 23.0757 18.5706C22.6269 19.0244 22.0563 19.33 21.44 19.4556C21.2371 19.497 21.0292 19.5188 20.819 19.52H20.48C19.9829 19.52 19.5741 19.8978 19.5249 20.3818C19.5216 20.4141 19.52 20.4469 19.52 20.48V20.7782L19.5201 20.7836L19.5689 20.7833C19.6958 21.1649 20.0558 21.44 20.48 21.44H20.8243H20.8297C21.0227 21.4389 21.2146 21.427 21.4044 21.4044C22.5497 21.2686 23.6219 20.7488 24.4408 19.9208C25.3954 18.9554 25.9275 17.6504 25.92 16.2929V11.2271C25.9275 9.86952 25.3954 8.56456 24.4408 7.59928C23.4862 6.63401 22.1872 6.0875 20.8297 6.08H20.8243L15.6558 6.07999L15.6504 6.08001C14.2928 6.08751 12.9939 6.63401 12.0392 7.59928C11.2338 8.41371 10.7291 9.46994 10.5956 10.5956C10.571 10.8032 10.559 11.0132 10.56 11.2245V11.52C10.56 11.9288 10.8156 12.278 11.1757 12.4164V12.48H11.179ZM15.6504 6.08001L15.6558 7.04V7.05155L15.6504 6.08001ZM20.8297 6.08L20.8243 7.05171V7.04L20.8297 6.08ZM16.3443 24.9485L16.339 24H16.3443V24.9485ZM16.3443 24.96L16.3497 25.92H16.3443V24.96ZM11.181 24H11.1757V24.96L11.181 24ZM11.1704 25.92H11.1757L11.1757 24.9612L11.1704 25.92ZM8.00002 20.7795V20.7845L7.04002 20.7795H8.00002ZM7.04002 20.7795H6.08002V20.7771L6.08004 20.7745L7.04002 20.7795ZM8.00004 15.7018L8.00001 15.6964L7.04002 15.7018H6.08002V15.7047L6.08004 15.7071L7.04002 15.7018H8.00004ZM16.3444 12.48H16.3411L16.339 12.48L16.3444 11.5084V11.52V12.48ZM16.3444 11.5084V10.56H16.3497L16.3444 11.5084ZM19.5201 15.7018V15.6964L20.4801 15.7018H19.5201ZM20.4801 15.7018H21.4401L21.44 15.7071L20.4801 15.7018Z"
                fill="#FF9900"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.179 12.48H11.52C12.0502 12.48 12.48 12.0502 12.48 11.52L12.48 11.2218L12.48 11.2164C12.4788 10.9943 12.5007 10.7744 12.5444 10.56C12.6675 9.95557 12.964 9.39465 13.4044 8.94939C14.0003 8.34671 14.8112 8.00526 15.6588 8H20.8212C21.6688 8.00526 22.4797 8.34671 23.0757 8.94939C23.6723 9.55259 24.0046 10.3681 24 11.2164V11.2218V16.2982L24.0001 16.3036C24.0047 17.1519 23.6723 17.9674 23.0757 18.5706C22.6269 19.0244 22.0563 19.33 21.44 19.4556C21.2371 19.497 21.0292 19.5188 20.819 19.52H20.48C19.9829 19.52 19.5741 19.8978 19.5249 20.3818C19.5216 20.4141 19.52 20.4469 19.52 20.48V20.7782L19.5201 20.7836L19.5689 20.7833C19.6958 21.1649 20.0558 21.44 20.48 21.44H20.8243H20.8297C21.0227 21.4389 21.2146 21.427 21.4044 21.4044C22.5497 21.2686 23.6219 20.7488 24.4408 19.9208C25.3954 18.9554 25.9275 17.6504 25.92 16.2929V11.2271C25.9275 9.86952 25.3954 8.56456 24.4408 7.59928C23.4862 6.63401 22.1872 6.0875 20.8297 6.08H20.8243L15.6558 6.07999L15.6504 6.08001C14.2928 6.08751 12.9939 6.63401 12.0392 7.59928C11.2338 8.41371 10.7291 9.46994 10.5956 10.5956C10.571 10.8032 10.559 11.0132 10.56 11.2245V11.52C10.56 11.9288 10.8156 12.278 11.1757 12.4164V12.48H11.179ZM15.6504 6.08001L15.6558 7.04V7.05155L15.6504 6.08001ZM20.8297 6.08L20.8243 7.05171V7.04L20.8297 6.08ZM16.3443 24.9485L16.339 24H16.3443V24.9485ZM16.3443 24.96L16.3497 25.92H16.3443V24.96ZM11.181 24H11.1757V24.96L11.181 24ZM11.1704 25.92H11.1757L11.1757 24.9612L11.1704 25.92ZM8.00002 20.7795V20.7845L7.04002 20.7795H8.00002ZM7.04002 20.7795H6.08002V20.7771L6.08004 20.7745L7.04002 20.7795ZM8.00004 15.7018L8.00001 15.6964L7.04002 15.7018H6.08002V15.7047L6.08004 15.7071L7.04002 15.7018H8.00004ZM16.3444 12.48H16.3411L16.339 12.48L16.3444 11.5084V11.52V12.48ZM16.3444 11.5084V10.56H16.3497L16.3444 11.5084ZM19.5201 15.7018V15.6964L20.4801 15.7018H19.5201ZM20.4801 15.7018H21.4401L21.44 15.7071L20.4801 15.7018Z"
                fill="black"
                fillOpacity="0.2"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.179 12.48H11.52C12.0502 12.48 12.48 12.0502 12.48 11.52L12.48 11.2218L12.48 11.2164C12.4788 10.9943 12.5007 10.7744 12.5444 10.56H11.52L11.1757 10.56L11.1704 10.56C10.9773 10.5611 10.7855 10.573 10.5956 10.5956C10.571 10.8032 10.559 11.0132 10.56 11.2245V11.52C10.56 11.9288 10.8156 12.278 11.1757 12.4164V12.48H11.179ZM15.661 7.99999L15.6588 8H15.6558V7.05155L15.661 7.99999ZM15.6558 7.04L15.6504 6.08001L15.6558 6.07999V7.04ZM20.8243 6.08V7.04L20.8297 6.08H20.8243ZM20.8212 8H20.8243V7.05171L20.819 7.99999L20.8212 8ZM25.92 11.2271V11.2218H24.96L24 11.2164V11.2218H24.96L25.92 11.2271ZM24.0001 16.3036L24 16.2982H24.96L24.0001 16.3036ZM24.96 16.2982L25.92 16.2929V16.2982H24.96ZM20.819 19.52C21.0292 19.5188 21.2371 19.497 21.44 19.4556L21.44 20.48V20.7729C21.4411 20.985 21.4291 21.1959 21.4044 21.4044C21.2146 21.427 21.0227 21.4389 20.8297 21.44H20.8243H20.48C20.0558 21.44 19.6958 21.1649 19.5689 20.7833L19.5201 20.7836L19.52 20.7782V20.48C19.52 20.4469 19.5216 20.4141 19.5249 20.3818C19.5741 19.8978 19.9829 19.52 20.48 19.52H20.819ZM16.3443 24.9485L16.339 24H16.3443V24.9485ZM16.3443 24.96L16.3497 25.92H16.3443V24.96ZM11.181 24H11.1757V24.96L11.181 24ZM11.1704 25.92H11.1757L11.1757 24.9612L11.1704 25.92ZM8.00002 20.7795V20.7845L7.04002 20.7795H8.00002ZM7.04002 20.7795H6.08002V20.7771L6.08004 20.7745L7.04002 20.7795ZM8.00004 15.7018L8.00001 15.6964L7.04002 15.7018H6.08002V15.7047L6.08004 15.7071L7.04002 15.7018H8.00004ZM16.3444 12.48H16.3411L16.339 12.48L16.3444 11.5084V11.52V12.48ZM16.3497 10.56L16.3444 11.5084V10.56H16.3497ZM19.5201 15.7018V15.6964L20.4801 15.7018H19.5201ZM21.44 15.7071L20.4801 15.7018H21.4401L21.44 15.7071Z"
                fill="#FF9900"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.179 12.48H11.52C12.0502 12.48 12.48 12.0502 12.48 11.52L12.48 11.2218L12.48 11.2164C12.4788 10.9943 12.5007 10.7744 12.5444 10.56H11.52L11.1757 10.56L11.1704 10.56C10.9773 10.5611 10.7855 10.573 10.5956 10.5956C10.571 10.8032 10.559 11.0132 10.56 11.2245V11.52C10.56 11.9288 10.8156 12.278 11.1757 12.4164V12.48H11.179ZM15.661 7.99999L15.6588 8H15.6558V7.05155L15.661 7.99999ZM15.6558 7.04L15.6504 6.08001L15.6558 6.07999V7.04ZM20.8243 6.08V7.04L20.8297 6.08H20.8243ZM20.8212 8H20.8243V7.05171L20.819 7.99999L20.8212 8ZM25.92 11.2271V11.2218H24.96L24 11.2164V11.2218H24.96L25.92 11.2271ZM24.0001 16.3036L24 16.2982H24.96L24.0001 16.3036ZM24.96 16.2982L25.92 16.2929V16.2982H24.96ZM20.819 19.52C21.0292 19.5188 21.2371 19.497 21.44 19.4556L21.44 20.48V20.7729C21.4411 20.985 21.4291 21.1959 21.4044 21.4044C21.2146 21.427 21.0227 21.4389 20.8297 21.44H20.8243H20.48C20.0558 21.44 19.6958 21.1649 19.5689 20.7833L19.5201 20.7836L19.52 20.7782V20.48C19.52 20.4469 19.5216 20.4141 19.5249 20.3818C19.5741 19.8978 19.9829 19.52 20.48 19.52H20.819ZM16.3443 24.9485L16.339 24H16.3443V24.9485ZM16.3443 24.96L16.3497 25.92H16.3443V24.96ZM11.181 24H11.1757V24.96L11.181 24ZM11.1704 25.92H11.1757L11.1757 24.9612L11.1704 25.92ZM8.00002 20.7795V20.7845L7.04002 20.7795H8.00002ZM7.04002 20.7795H6.08002V20.7771L6.08004 20.7745L7.04002 20.7795ZM8.00004 15.7018L8.00001 15.6964L7.04002 15.7018H6.08002V15.7047L6.08004 15.7071L7.04002 15.7018H8.00004ZM16.3444 12.48H16.3411L16.339 12.48L16.3444 11.5084V11.52V12.48ZM16.3497 10.56L16.3444 11.5084V10.56H16.3497ZM19.5201 15.7018V15.6964L20.4801 15.7018H19.5201ZM21.44 15.7071L20.4801 15.7018H21.4401L21.44 15.7071Z"
                fill="white"
              />
              <path
                d="M8.24178 12.7543L8.92435 13.4294C9.52043 12.8267 10.3313 12.4852 11.179 12.48H11.1757V12.4164C10.8156 12.278 10.56 11.9288 10.56 11.52V11.2245C10.559 11.0132 10.571 10.8032 10.5956 10.5956C9.45032 10.7314 8.37807 11.2513 7.5592 12.0793L8.24178 12.7543Z"
                fill="#FF9900"
              />
              <path
                d="M8.24178 12.7543L8.92435 13.4294C9.52043 12.8267 10.3313 12.4852 11.179 12.48H11.1757V12.4164C10.8156 12.278 10.56 11.9288 10.56 11.52V11.2245C10.559 11.0132 10.571 10.8032 10.5956 10.5956C9.45032 10.7314 8.37807 11.2513 7.5592 12.0793L8.24178 12.7543Z"
                fill="white"
              />
              <path
                d="M8.92435 13.4294L8.24178 12.7543L7.5592 12.0793C6.60513 13.044 6.07313 14.348 6.08002 15.7047V15.7018H7.04002L8.00001 15.6964C7.9953 14.8481 8.32779 14.0326 8.92435 13.4294Z"
                fill="#FF9900"
              />
              <path
                d="M8.92435 13.4294L8.24178 12.7543L7.5592 12.0793C6.60513 13.044 6.07313 14.348 6.08002 15.7047V15.7018H7.04002L8.00001 15.6964C7.9953 14.8481 8.32779 14.0326 8.92435 13.4294Z"
                fill="white"
              />
              <path
                d="M8.00004 15.7018H7.04002L6.08004 15.7071L6.08002 15.7047V20.7771L6.08004 20.7745L7.04002 20.7795H8.00002L8.00004 15.7018Z"
                fill="#FF9900"
              />
              <path
                d="M8.00004 15.7018H7.04002L6.08004 15.7071L6.08002 15.7047V20.7771L6.08004 20.7745L7.04002 20.7795H8.00002L8.00004 15.7018Z"
                fill="white"
              />
              <path
                d="M7.04002 20.7795H6.08002V20.7771C6.06658 23.6023 8.34486 25.9044 11.1704 25.92L11.1757 24.9612L11.1757 24.96V24H11.181C9.41478 23.9903 7.99076 22.5508 8.00002 20.7845L7.04002 20.7795Z"
                fill="#FF9900"
              />
              <path
                d="M7.04002 20.7795H6.08002V20.7771C6.06658 23.6023 8.34486 25.9044 11.1704 25.92L11.1757 24.9612L11.1757 24.96V24H11.181C9.41478 23.9903 7.99076 22.5508 8.00002 20.7845L7.04002 20.7795Z"
                fill="white"
              />
              <path
                d="M16.3443 25.92V24.96V24.9485L16.339 24H11.181L11.1757 24.96L11.1757 24.9612L11.1757 25.92H16.3443Z"
                fill="#FF9900"
              />
              <path
                d="M16.3443 25.92V24.96V24.9485L16.339 24H11.181L11.1757 24.96L11.1757 24.9612L11.1757 25.92H16.3443Z"
                fill="white"
              />
              <path
                d="M16.339 24H16.3443V24.9485L16.3497 25.92C17.7072 25.9124 19.0062 25.366 19.9608 24.4008L18.5957 23.0506C17.9992 23.6538 17.1874 23.9953 16.339 24Z"
                fill="#FF9900"
              />
              <path
                d="M16.339 24H16.3443V24.9485L16.3497 25.92C17.7072 25.9124 19.0062 25.366 19.9608 24.4008L18.5957 23.0506C17.9992 23.6538 17.1874 23.9953 16.339 24Z"
                fill="white"
              />
              <path
                d="M20.8297 21.44H20.8243H20.48C20.0558 21.44 19.6958 21.1649 19.5689 20.7833L19.5201 20.7836C19.5247 21.6319 19.1923 22.4474 18.5957 23.0506L19.9608 24.4008C20.7662 23.5863 21.2709 22.53 21.4044 21.4044C21.2146 21.427 21.0227 21.4389 20.8297 21.44Z"
                fill="#FF9900"
              />
              <path
                d="M20.8297 21.44H20.8243H20.48C20.0558 21.44 19.6958 21.1649 19.5689 20.7833L19.5201 20.7836C19.5247 21.6319 19.1923 22.4474 18.5957 23.0506L19.9608 24.4008C20.7662 23.5863 21.2709 22.53 21.4044 21.4044C21.2146 21.427 21.0227 21.4389 20.8297 21.44Z"
                fill="white"
              />
              <path
                d="M20.4801 15.7018H19.5201L19.52 20.48C19.52 20.4469 19.5216 20.4141 19.5249 20.3818C19.5741 19.8978 19.9829 19.52 20.48 19.52H20.819C21.0292 19.5188 21.2371 19.497 21.44 19.4556L21.4401 15.7018L21.44 15.7071L20.4801 15.7018Z"
                fill="#FF9900"
              />
              <path
                d="M20.4801 15.7018H19.5201L19.52 20.48C19.52 20.4469 19.5216 20.4141 19.5249 20.3818C19.5741 19.8978 19.9829 19.52 20.48 19.52H20.819C21.0292 19.5188 21.2371 19.497 21.44 19.4556L21.4401 15.7018L21.44 15.7071L20.4801 15.7018Z"
                fill="white"
              />
              <path
                d="M20.4801 15.7018H21.4401C21.442 15.2701 21.3893 14.8437 21.2859 14.4319C21.2654 14.3503 21.2429 14.2693 21.2185 14.1889C21.1888 14.0913 21.1562 13.9946 21.1207 13.899C21.0763 13.7793 21.0275 13.6613 20.9743 13.5453C20.871 13.32 20.7513 13.102 20.6159 12.8933C20.5573 12.803 20.4958 12.7144 20.4314 12.6277C20.2885 12.4351 20.1314 12.2518 19.9608 12.0793L18.5957 13.4294C19.1923 14.0326 19.5247 14.8481 19.5201 15.6964L20.4801 15.7018Z"
                fill="#FF9900"
              />
              <path
                d="M20.4801 15.7018H21.4401C21.442 15.2701 21.3893 14.8437 21.2859 14.4319C21.2654 14.3503 21.2429 14.2693 21.2185 14.1889C21.1888 14.0913 21.1562 13.9946 21.1207 13.899C21.0763 13.7793 21.0275 13.6613 20.9743 13.5453C20.871 13.32 20.7513 13.102 20.6159 12.8933C20.5573 12.803 20.4958 12.7144 20.4314 12.6277C20.2885 12.4351 20.1314 12.2518 19.9608 12.0793L18.5957 13.4294C19.1923 14.0326 19.5247 14.8481 19.5201 15.6964L20.4801 15.7018Z"
                fill="white"
              />
              <path
                d="M18.5957 13.4294L19.9608 12.0793C19.0062 11.114 17.7072 10.5675 16.3497 10.56L16.3444 11.5084V11.52V12.48H16.3411C17.1887 12.4852 17.9997 12.8267 18.5957 13.4294Z"
                fill="#FF9900"
              />
              <path
                d="M18.5957 13.4294L19.9608 12.0793C19.0062 11.114 17.7072 10.5675 16.3497 10.56L16.3444 11.5084V11.52V12.48H16.3411C17.1887 12.4852 17.9997 12.8267 18.5957 13.4294Z"
                fill="white"
              />
              <path
                d="M12.48 11.52C12.48 12.0502 12.0502 12.48 11.52 12.48H16.3411L16.339 12.48L16.3444 11.5084V10.56H12.5444C12.5007 10.7744 12.4788 10.9943 12.48 11.2164L12.48 11.2218L12.48 11.52Z"
                fill="#FF9900"
              />
              <path
                d="M12.48 11.52C12.48 12.0502 12.0502 12.48 11.52 12.48H16.3411L16.339 12.48L16.3444 11.5084V10.56H12.5444C12.5007 10.7744 12.4788 10.9943 12.48 11.2164L12.48 11.2218L12.48 11.52Z"
                fill="white"
              />
            </svg>
          </div>
          <Link to={`steam://${address}`} className="w-[17px] h-[18px]">
            <div className="w-[17px] h-[18px]bg-amber-500 rounded-full">
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="8.79602" cy="8.79602" r="8.79602" fill="#FF9900" />
                <path
                  d="M13.1941 9.23582L5.93737 13.4255L5.93738 5.04615L13.1941 9.23582Z"
                  fill="white"
                />
              </svg>
            </div>
          </Link>
        </div>
      </div>
      <div className="w-[221px] h-[27px] flex justify-center content-center items-center bg-white bg-opacity-60 rounded-[15px]">
        <ProgressBar
          online={players ?? 0}
          maxonline={maxplayers ?? 100}
          query={quene ?? 0}
        />
        <span
          className={
            "absolute mt-8 ml-4 w-full h-full text-left text-black font-bold"
          }
        >
          {players}/{maxplayers}
        </span>
      </div>
    </div>
  );
}

export default Server;
