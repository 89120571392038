import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "../../api/axios";
export interface Session {
  id: number;
  name: string;
  steamid: string;
  balance: number;
  imageUrl: string;
  personalSaleId: number;
  isAdmin: boolean;
  isModerator: boolean;
  createAt: Date;
}

export interface UserSlicer {
  user: Session | null;
  status: "loaded" | "loading" | "failed";
}

const initialState: UserSlicer = {
  user: null,
  status: "loading",
};
export const fetchSession = createAsyncThunk("user/fetchSession", async () => {
  const response = await axios.get("/user/getSessions");
  return response.data;
});

export const userSlicer = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSession.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSession.fulfilled, (state, action) => {
        state.status = "loaded";
        state.user = action.payload;
      })
      .addCase(fetchSession.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = userSlicer.actions;
export const selectUser = (state: RootState) => state.user.user;
export const selectUserStatus = (state: RootState) => state.user.status;

export default userSlicer.reducer;
