import React, { useEffect, useState } from "react";
import Loading from "../../../../../components/loading/Loading";
import axios from "../../../../../../api/axios";
import {
  IItemAdmin,
  IItemAdminResponse,
} from "../../../../../../api/admin.entity";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Field, Form, Formik, FormikHelpers, useFormikContext } from "formik";

import Modal from "@mui/material/Modal";
import Notification from "../../../../../components/notify/Notify";

function Items() {
  const { setFieldValue } = useFormikContext();
  const [items, setItems] = useState<IItemAdminResponse>();
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<IItemAdmin>();
  const [notification, setNotification] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);
  const fetchUsers = async () => {
    const { data } = await axios.get<IItemAdminResponse>(
      "/admin/allItems?page=1&limit=10",
    );
    console.log(data);
    setItems(data);
  };
  useEffect(() => {
    fetchUsers();
  }, []);
  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  const handleClose = (event: {}, reason: string) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      setOpenModal(false);
    }
  };
  const showNotification = (message: string, type: "success" | "error") => {
    setNotification({ message, type });
  };
  if (!items) {
    return <Loading />;
  }
  return (
    <div className={"w-full h-full flex flex-col gap-4"}>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
      <div className={"w-full flex justify-between items-center"}>
        <div className={"flex gap-3 h-full"}>
          <input
            className={
              "w-full rounded-3xl border-2 border-emerald-600 bg-transparent px-4 py-2"
            }
            id={"search"}
            value={search}
            onChange={onChangeSearch}
            placeholder={"Поиск"}
          />
        </div>
        <div>
          <button
            className={"rounded-3xl bg-emerald-600 text-center px-4 py-2"}
          >
            Добавить предмет
          </button>
        </div>
      </div>
      <table className={"min-w-full h-full"}>
        <thead>
          <tr
            className={
              "text-amber-500 font-bold bg-[#242424] h-10 rounded-t-full rounded-tl-full rounded-tr-full border-b border-b-slate-500"
            }
          >
            <th className={"pl-4 pr-4"}></th>
            <th className={"pl-4 pr-4"}>ID</th>
            <th className={"pl-4 pr-4"}>Имя</th>
            <th className={"pl-4 pr-4"}>Цена</th>
            <th className={"pl-4 pr-4"}>Категория</th>
            <th className={"pl-4 pr-4"}>Тип</th>
            <th className={"pl-4 pr-4"}>Сервер</th>
            <th className={"pl-4 pr-4"}>Куплено</th>
            <th className={"pl-4 pr-4"}>Дата создания</th>
            <th className={"pl-4 pr-4"}>Дата изменения</th>
            <th className={"pl-4 pr-4"}>Управление</th>
          </tr>
        </thead>
        <tbody>
          {items.items.map((item) => (
            <tr
              key={item.id}
              className={
                (item.enabled
                  ? "odd:bg-[#1C1C1C] even:bg-[#242424] "
                  : "bg-[#6C0000] opacity-30 ") +
                "text-white bg-[#1C1C1C] text-center h-10 border-b border-b-slate-500 last:border-b-0 last:rounded-bl-full last:rounded-br-full last:rounded-b-full"
              }
            >
              <td className={"pl-4 pr-4"}>
                {" "}
                <img
                  className={"w-10 h-10 mr-3 rounded-full"}
                  src={item.imageUrl}
                  alt={"Avatar"}
                />
              </td>
              <td className={"pl-4 pr-4"}>{item.id}</td>
              <td className={"pl-4 pr-4"}>{item.name}</td>
              <td className={"pl-4 pr-4"}>{item.cost}</td>
              <td className={"pl-4 pr-4"}>{item.category}</td>
              <td className={"pl-4 pr-4"}>{item.type}</td>
              <td className={"pl-4 pr-4"}>{item.server}</td>
              <td className={"pl-4 pr-4"}>{item.buyCount}</td>
              <td className={"pl-4 pr-4"}>
                {new Date(item.createAt).toLocaleString("ru-RU")}
              </td>{" "}
              <td className={"pl-4 pr-4"}>
                {new Date(item.updatedAt).toLocaleString("ru-RU")}
              </td>
              <td className={"pl-4 pr-4 content-center"}>
                <button
                  onClick={() => {
                    setOpenModal(true);
                    setCurrentItem(item);
                  }}
                  className={"rounded-full bg-emerald-600 w-7 h-7 mr-2"}
                >
                  <EditIcon className={"scale-90 text-white select-none"} />
                </button>
                <button className={"rounded-full bg-rose-700 w-7 h-7 "}>
                  <DeleteForeverIcon
                    className={"scale-90 text-white select-none"}
                  />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        onClose={handleClose}
        open={openModal}
        className="flex items-center justify-center"
      >
        <div className="relative overflow-auto max-h-full">
          <button
            onClick={() => setOpenModal(false)}
            className="absolute right-4 top-4"
          >
            ✖
          </button>
          {currentItem && (
            <Formik
              onSubmit={async (
                values: IItemAdmin,
                { setSubmitting }: FormikHelpers<IItemAdmin>,
              ) => {
                try {
                  const {
                    id,
                    server,
                    category,
                    updatedAt,
                    createAt,
                    buyCount,
                    ...otherValues
                  } = values;
                  await axios.patch(`/item/${id}`, otherValues);
                  showNotification("Удачно", "success");
                  fetchUsers();
                  setSubmitting(false);
                  setOpenModal(false);
                } catch (e: any) {
                  showNotification(e?.message, "error");
                  setOpenModal(false);
                }
              }}
              initialValues={currentItem}
            >
              <Form className="flex flex-col w-96 p-10 bg-[#242424] rounded-2xl text-amber-500 gap-3 justify-center items-center">
                <label htmlFor="index">Индекс</label>
                <Field
                  id="index"
                  className="text-black p-1 w-full rounded-2xl outline-none"
                  name="index"
                  onChange={(e: any) => {
                    setFieldValue("index", parseInt(e.target.value));
                  }}
                  autocomplete="off"
                  type="text"
                />
                <label htmlFor="name">Название</label>
                <Field
                  id="name"
                  name="name"
                  type="text"
                  autocomplete="off"
                  className="text-black p-1 w-full rounded-2xl outline-none"
                />
                <label htmlFor="description">Описание</label>
                <Field
                  as="textarea"
                  id="description"
                  name="description"
                  type="text"
                  className="text-black p-1 w-full rounded-2xl outline-none"
                />
                <label htmlFor="type">Тип</label>
                <Field
                  title="COMMAND"
                  id="type"
                  name="type"
                  autocomplete="off"
                  type="text"
                  className="text-black p-1 w-full rounded-2xl outline-none"
                />
                <label htmlFor="cost">Цена</label>
                <Field
                  id="cost"
                  name="cost"
                  autocomplete="off"
                  onChange={(e: any) => {
                    setFieldValue("cost", parseInt(e.target.value));
                  }}
                  type="text"
                  className="text-black p-1 w-full rounded-2xl outline-none"
                />
                <label htmlFor="sale">Скидка</label>
                <Field
                  id="sale"
                  name="sale"
                  autocomplete="off"
                  onChange={(e: any) => {
                    setFieldValue("sale", parseInt(e.target.value));
                  }}
                  type="text"
                  className="text-black p-1 w-full rounded-2xl outline-none"
                />
                <label htmlFor="minCount">Минимальное количество</label>
                <Field
                  id="minCount"
                  name="minCount"
                  autocomplete="off"
                  onChange={(e: any) => {
                    setFieldValue("minCount", parseInt(e.target.value));
                  }}
                  type="text"
                  className="text-black p-1 w-full rounded-2xl outline-none"
                />
                <label htmlFor="shotname">Короткое имя</label>
                <Field
                  id="shotname"
                  name="shotname"
                  autocomplete="off"
                  type="text"
                  className="text-black p-1 w-full rounded-2xl outline-none"
                />
                <label htmlFor="command">Команда</label>
                <Field
                  id="command"
                  name="command"
                  autocomplete="off"
                  type="text"
                  className="text-black p-1 w-full rounded-2xl outline-none"
                />
                <label htmlFor="categoryId">ID Категории</label>
                <Field
                  id="categoryId"
                  name="categoryId"
                  autocomplete="off"
                  onChange={(e: any) => {
                    setFieldValue("categoryId", parseInt(e.target.value));
                  }}
                  type="text"
                  className="text-black p-1 w-full rounded-2xl outline-none"
                />
                <label htmlFor="serverId">ID Сервера</label>
                <Field
                  id="serverId"
                  onChange={(e: any) => {
                    setFieldValue("serverId", parseInt(e.target.value));
                  }}
                  name="serverId"
                  type="text"
                  autocomplete="off"
                  className="text-black p-1 w-full rounded-2xl outline-none"
                />
                <label htmlFor="imageUrl">Ссылка на каринтку</label>
                <Field
                  id="imageUrl"
                  name="imageUrl"
                  autocomplete="off"
                  type="text"
                  className="text-black p-1 w-full rounded-2xl outline-none"
                />
                <label htmlFor="buyCount">Количество к покупке</label>
                <Field
                  id="buyCount"
                  name="buyCount"
                  onChange={(e: any) => {
                    setFieldValue("buyCount", parseInt(e.target.value));
                  }}
                  autocomplete="off"
                  type="text"
                  className="text-black p-1 w-full rounded-2xl outline-none"
                />
                <button
                  className="cursor-pointer text-l px-10 py-2 rounded text-black font-semibold bg-amber-500 mt-3"
                  type="submit"
                >
                  Submit
                </button>
              </Form>
            </Formik>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default Items;
