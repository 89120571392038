import React, { useState } from "react";

function Banner() {
  const banners = ["img_1.png", "img_1.png", "img.png"];
  const [active, setActive] = useState(0);

  const listRef = React.useRef<HTMLUListElement>(null);
  const onClickNext = () => {
    if (active === banners.length - 1) {
      return;
    }
    setActive((prev) => prev + 1);
    console.log(active);
    if (!listRef.current) return;
    listRef.current.style.left = `${
      ((active + 1) / (banners.length - 1)) * -100
    }%`;
    listRef.current.style.transform = `translate3d(${
      ((active + 1) / (banners.length - 1)) * -100
    }%, 0,0)`;
  };
  const onClickBack = () => {
    if (active === 0) return;
    setActive((prev) => prev - 1);
    if (!listRef.current) return;
    listRef.current.style.left = `${
      ((active - 1) / (banners.length - 1)) * -100
    }%`;
    listRef.current.style.transform = `translate3d(${
      ((active - 1) / (banners.length - 1)) * -100
    }%, 0,0)`;
  };
  return (
    <div
      className={"relative w-[962px] h-[210px] bg-black flex overflow-hidden"}
    >
      <button
        onClick={() => onClickBack()}
        className={"absolute left-0 top-[40%]  z-10 bg-transparent"}
      >
        BACK
      </button>
      <ul
        ref={listRef}
        className={`relative inline-flex transition-all duration-1000 ease-in-out overflow-hidden`}
      >
        {banners.map((p) => (
          <li className="Image7 min-w-full h-full rounded-[26px] flex justify-center items-center">
            <img src={p} alt="Banner" />
          </li>
        ))}
      </ul>
      <button
        onClick={() => onClickNext()}
        className={"absolute right-0 top-[40%] z-10 bg-transparent"}
      >
        NEXT
      </button>
    </div>
  );
}

export default Banner;
