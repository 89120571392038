import React from "react";

const ProgressBar = ({
  online,
  maxonline,
  query,
}: {
  online: number;
  maxonline: number;
  query: number;
}) => {
  const progress = (online / maxonline) * 100;

  return (
    <div className="relative w-full h-full">
      <div className="flex w-full h-full mb-2 items-center">
        <div className="flex w-full h-full relative bg-graay-200 rounded-full">
          <div
            className=" bg-amber-600 custom-gradient h-full rounded-full animate-progressbarstripes"
            style={{ width: `${progress < 5 ? 6 : progress}%` }}
          ></div>
          <div
            className="bg-sky-600 custom-gradient h-full rounded-tr-full rounded-br-full animate-progressbarstripes"
            style={{ width: `${(query / (maxonline + query)) * 100}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
