import React, { useEffect, useState } from "react";
import classNames from "classnames";

interface NotificationProps {
  message: string;
  type: "success" | "error";
  onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({
  message,
  type,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 3000); // Display notification for 3 seconds

    return () => clearTimeout(timeoutId);
  }, [onClose]);

  const notificationClasses = classNames({
    "fixed ": true,
    "top-5": true,
    "right-5": true,
    "min-w-[220px]": true,
    "bg-green-500": type === "success",
    "bg-red-500": type === "error",
    "text-white": true,
    "p-4": true,
    "rounded-md": true,
    "opacity-0": !isVisible,
    "transition-opacity": true,
    "duration-300": true, // Add duration class
    "ease-in-out": true, // Add easing class
    "z-[10000]": true, // Set z-index to 50 or adjust as needed
  });

  return (
    <div className={notificationClasses}>
      <span className={"mr-2"}>{message}</span>
      <button
        className="float-right text-white"
        onClick={() => {
          setIsVisible(false);
          onClose();
        }}
      >
        &#x2715;
      </button>
    </div>
  );
};

export default Notification;
