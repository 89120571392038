import React, { useState } from "react";
import PopupDeposit from "./PopupDeposit";
import PopupPromo from "./PopupPromo";
import { useAppSelector } from "../../../../app/hooks";
import { selectUser } from "../../../../app/user/userSlice";
import { useNavigate } from "react-router-dom";

function BalanceDrop({
  balanceOpen,
  setBalanceOpen,
}: {
  balanceOpen: boolean;
  setBalanceOpen: (open: boolean) => void;
}) {
  const user = useAppSelector(selectUser);
  const navagate = useNavigate();
  const [hiddenDeposit, setHiddenDeposit] = useState(true);
  const handleDeposit = () => {
    setHiddenDeposit(!hiddenDeposit);
  };
  const [hiddenPromo, setHiddenPromo] = useState(true);
  const handlePromo = () => {
    setHiddenPromo(!hiddenPromo);
  };
  const handleAdmin = () => {
    navagate("/admin");
  };
  return (
    <div className="absolute z-40 w-full top-10 rounded-b-lg text-center flex flex-col justify-center content-center items-start">
      {!hiddenDeposit && (
        <PopupDeposit hidden={hiddenDeposit} setHidden={setHiddenDeposit} />
      )}
      {!hiddenPromo && (
        <PopupPromo hidden={hiddenPromo} setHidden={setHiddenPromo} />
      )}
      {/* Ваше содержимое выпадающего меню */}
      <ul className="w-[230px] mt-4 flex flex-col justify-center content-center items-center">
        <li
          onClick={handleDeposit}
          className={
            "bg-zinc-600 flex content-center items-center w-full mb-2 py-2 cursor-pointer rounded-tl-[5px] rounded-tr-[10px] rounded-bl-[5px] rounded-br-[10px] transition-opacity duration-500 ease-in-out " +
            (!balanceOpen ? "opacity-0" : "opacity-100")
          }
        >
          <svg
            className="ml-5"
            width="18"
            height="13"
            viewBox="0 0 18 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.56 0H1.44C0.648 0 0 0.648 0 1.44V2.94912H18V1.44C18 0.648 17.352 0 16.56 0Z"
              fill="url(#paint0_linear_100_210)"
            />
            <path
              d="M0 10.8C0 11.592 0.648 12.24 1.44 12.24H16.56C17.352 12.24 18 11.592 18 10.8V7.78467H0V10.8Z"
              fill="url(#paint1_linear_100_210)"
            />
            <path
              d="M3.80304 3.46533H0V7.26837H3.80304V3.46533Z"
              fill="url(#paint2_linear_100_210)"
            />
            <path
              d="M4.52295 7.2691H17.9999V3.46606H4.52295V7.2691ZM16.5599 4.59142C16.989 4.59142 17.3353 4.93846 17.3353 5.36686C17.3353 5.79526 16.989 6.1423 16.5599 6.1423C16.1308 6.1423 15.7845 5.79526 15.7845 5.36686C15.7845 4.93846 16.1308 4.59142 16.5599 4.59142Z"
              fill="url(#paint3_linear_100_210)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_100_210"
                x1="26.5"
                y1="0.361411"
                x2="1.47451"
                y2="17.6723"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FF9900" />
                <stop offset="1" stopColor="#FF5318" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_100_210"
                x1="26.5"
                y1="8.33067"
                x2="-4.08737"
                y2="22.3359"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FF9900" />
                <stop offset="1" stopColor="#FF5318" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_100_210"
                x1="5.59892"
                y1="3.93139"
                x2="-2.1193"
                y2="4.80612"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FF9900" />
                <stop offset="1" stopColor="#FF5318" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_100_210"
                x1="24.364"
                y1="3.93212"
                x2="0.509198"
                y2="13.5128"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FF9900" />
                <stop offset="1" stopColor="#FF5318" />
              </linearGradient>
            </defs>
          </svg>

          <span className={"ml-2 text-white text-[13px] font-normal"}>
            Пополнить
          </span>
        </li>
        <li
          onClick={handlePromo}
          className={
            "bg-zinc-600 flex content-center items-center w-full mb-2 py-2 cursor-pointer rounded-tl-[5px] rounded-tr-[10px] rounded-bl-[5px] rounded-br-[10px] transition-opacity duration-500 ease-in-out " +
            (!balanceOpen ? "opacity-0" : "opacity-100")
          }
        >
          <svg
            className={"ml-5"}
            width="19"
            height="7"
            viewBox="0 0 19 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.915183 5.97714L2.34116 4.01907L0.198644 3.38057L0.532083 2.31641L2.6746 3.10389L2.61075 0.663402H3.68911L3.61816 3.14646L5.73231 2.35897L6.05865 3.44442L3.88066 4.09002L5.27826 6.01261L4.39855 6.67239L3.08608 4.62919L1.80199 6.62273L0.915183 5.97714ZM7.17248 5.97714L8.59846 4.01907L6.45594 3.38057L6.78938 2.31641L8.9319 3.10389L8.86805 0.663402H9.9464L9.87546 3.14646L11.9896 2.35897L12.3159 3.44442L10.138 4.09002L11.5356 6.01261L10.6558 6.67239L9.34338 4.62919L8.05928 6.62273L7.17248 5.97714ZM13.4298 5.97714L14.8558 4.01907L12.7132 3.38057L13.0467 2.31641L15.1892 3.10389L15.1253 0.663402H16.2037L16.1328 3.14646L18.2469 2.35897L18.5732 3.44442L16.3952 4.09002L17.7929 6.01261L16.9131 6.67239L15.6007 4.62919L14.3166 6.62273L13.4298 5.97714Z"
              fill="url(#paint0_linear_100_218)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_100_218"
                x1="19"
                y1="-2"
                x2="1.52537"
                y2="9.67381"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#DD8502" />
                <stop offset="1" stopColor="#EE4E17" />
              </linearGradient>
            </defs>
          </svg>

          <span className={"ml-2 text-white text-[13px] font-normal"}>
            Промокод
          </span>
        </li>{" "}
        {user?.isAdmin && (
          <li
            onClick={handleAdmin}
            className={
              "bg-zinc-600 flex content-center items-center w-full mb-2 py-2 cursor-pointer rounded-tl-[5px] rounded-tr-[10px] rounded-bl-[5px] rounded-br-[10px] transition-opacity duration-500 ease-in-out " +
              (!balanceOpen ? "opacity-0" : "opacity-100")
            }
          >
            <svg
              className={"w-10 h-10"}
              fill="#EE4E17"
              viewBox="-192 -192 2304.00 2304.00"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#EE4E17"
              transform="matrix(-1, 0, 0, -1, 0, 0)rotate(0)"
              strokeWidth="0.019200000000000002"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
                stroke="#CCCCCC"
                strokeWidth="3.84"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M1556.611 1920c-54.084 0-108.168-20.692-149.333-61.857L740.095 1190.96c-198.162 41.712-406.725-19.269-550.475-163.019C14.449 852.771-35.256 582.788 65.796 356.27l32.406-72.696 390.194 390.193c24.414 24.305 64.266 24.305 88.68 0l110.687-110.686c11.824-11.934 18.283-27.59 18.283-44.34 0-16.751-6.46-32.516-18.283-44.34L297.569 84.207 370.265 51.8C596.893-49.252 866.875.453 1041.937 175.515c155.026 155.136 212.833 385.157 151.851 594.815l650.651 650.651c39.961 39.852 61.967 92.95 61.967 149.443 0 56.383-22.006 109.482-61.967 149.334l-138.275 138.385c-41.275 41.165-95.36 61.857-149.553 61.857Z"
                  fill-rule="evenodd"
                ></path>{" "}
              </g>
            </svg>

            <span className={"ml-2 text-white text-[13px] font-normal"}>
              Админ панель
            </span>
          </li>
        )}
        `{/* Другие пункты меню */}
      </ul>
    </div>
  );
}

export default BalanceDrop;
