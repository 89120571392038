import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getAllCategory,
  getCategoryes,
} from "../../../app/category/categorySlice";
import {
  selectCategory,
  setSelectCategory,
} from "../../../app/filter/filterSlice";

function Categories() {
  const [disable, setDisable] = useState(true);

  const category = useAppSelector(getCategoryes);
  const categorySelect = useAppSelector(selectCategory);
  const categoryes = [{ id: 0, name: "Всё" }, ...category];
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getAllCategory());
  }, []);
  const onClickSelectCategory = (id: number) => {
    dispatch(setSelectCategory(id));
  };
  return (
    <div
      className={
        "w-full mt-2 flex flex-col text-white justify-start content-start items-start"
      }
    >
      <div
        onClick={() => setDisable(!disable)}
        className={
          " cursor-pointer select-none w-full text-center h-10 bg-[#1B1B1B80] text-[15px] text-white rounded-md flex items-center flex-row justify-center content-center"
        }
      >
        <div className={"w-auto ml-5"}></div>
        <span>Категории</span>
        <div className={"w-auto ml-5"}>
          <svg
            width="22"
            height="10"
            viewBox="0 0 22 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1H21"
              stroke="#DF8701"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M1 5H21"
              stroke="#DF8701"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M1 9H21"
              stroke="#DF8701"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>
      <div
        className={
          disable
            ? "opacity-0 "
            : "opacity-100 transition-opacity duration-500 grid grid-cols-1 w-full gap-2 mt-2"
        }
      >
        {categoryes.map((server, i) => (
          <button
            key={server.id}
            onClick={() => onClickSelectCategory(server.id)}
            className={
              categorySelect === i
                ? "cursor-pointer w-full text-center h-10 bg-[#E28801] text-[#0E0E0E] font-bold rounded-md col-span-1"
                : "w-[240px] text-[14px] text-center h-10 bg-[#1B1B1B80] text-white transition-all duration-500 ease-in-out"
            }
          >
            {server.name}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Categories;
