import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import axios from "../../../api/axios";
import { fetchSession } from "../../../app/user/userSlice";
import Notification from "../notify/Notify";
import { useAppDispatch } from "../../../app/hooks";

export default function ItemFull({
  hidden,
  close,
  id,
  name,
  description,
  cost,
  sale,
  imageUrl,
  minCount,
  type,
}: {
  hidden: boolean;
  close: any;
  id: number;
  name: string;
  description: string;
  cost: number;
  sale: number;
  minCount: number;
  imageUrl: string;
  type: string;
}) {
  const dispatch = useAppDispatch();
  const [disable, setDisable] = useState(false);
  const [notification, setNotification] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);

  const showNotification = (message: string, type: "success" | "error") => {
    setNotification({ message, type });
  };
  const [anim, setAnim] = useState(true);
  useEffect(() => {
    setAnim(hidden);
  }, []);
  const [value, setValue] = useState(minCount);
  const handleAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      setValue(minCount);
      return;
    }
    if (Number(event.target.value) % minCount !== 0) {
      setValue(Math.floor(Number(event.target.value) / minCount) * minCount);
      return;
    }
    if (!Number(event.target.value)) return;
    setValue(Number(event.target.value));
  };
  const onClickPlus = () => {
    setValue((prev) => Number(prev) + minCount);
  };
  const onClickMinus = () => {
    if (Number(value) <= minCount) return;
    setValue((prev) => prev - minCount);
  };
  const onClickBuy = async () => {
    try {
      setDisable(true);
      await axios.post(`/cart`, {
        itemid: id,
        count: value,
      });
      showNotification("Покупка прошла успешно", "success");

      dispatch(fetchSession());
    } catch (error: any) {
      console.log(error.response.data.message);
      showNotification(error.response.data.message, "error");
    }

    setDisable(false);
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="fixed inset-0 z-50 overflow-hidden">
      <div
        className={
          "absolute inset-1 bg-stone-900 blur-[90px] transition-all duration-1000 ease-in-out" +
          (anim ? " opacity-0" : " opacity-100")
        }
      ></div>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
      <div className="absolute inset-0 flex items-center justify-center text-white">
        <div
          className={
            "itemFull border border-amber-500 rounded-2xl bg-[#0E0E0E] flex flex-col justify-between content-center items-center  sm:min-w-[400px] sm:min-h-[600px] transition-all duration-1000 ease-in-out transform " +
            (anim ? "-translate-y-full" : "translate-y-0") +
            (anim ? " opacity-0" : " opacity-100")
          }
        >
          <span
            className={
              " mt-2 text-white text-[20px] sm:text-[23px] font-semibold"
            }
          >
            {name}
          </span>
          <img
            className={
              "mt-7 sm:mt-0 w-[200px] h-[200px] sm:w-[250px] sm:h-[250px]"
            }
            src={imageUrl}
            alt={"Icon"}
          />
          {description && (
            <div
              className={
                "flex flex-col border border-[#EE4E17] leading-4 rounded-[8px] w-[90%] p-2 min-h-[250px] flex-shrink-0 justify-center content-center items-center text-[14px] bg-[#171717]"
              }
            >
              <pre className={"whitespace-pre-wrap"}>
                <Markdown className={"text-center"}>{description}</Markdown>
              </pre>
            </div>
          )}
          {type !== "COMMAND" && cost - cost * (sale / 100) !== 0 && (
            <div
              className={
                "flex flex-col justify-center items-center content-center w-[172px] h-[70px] relative"
              }
            >
              <span className={"opacity-50"}>Количество</span>
              <div
                className={"flex content-between items-center justify-between"}
              >
                <button
                  onClick={onClickMinus}
                  className={
                    "flex justify-center content-center items-center w-[44px] h-[23px] rotate-180 bg-orange-600 rounded-tr-lg rounded-br-lg text-center text-[20px]"
                  }
                >
                  <div>-</div>
                </button>
                <input
                  onChange={handleAmount}
                  value={value}
                  className={
                    "w-[100px] h-[22px] bg-neutral-900 border-b border-t border-orange-600 text-center"
                  }
                  placeholder={"1"}
                ></input>
                <button
                  onClick={onClickPlus}
                  className={
                    "w-[44px] h-[23px] bg-orange-600 rounded-tr-lg rounded-br-lg "
                  }
                >
                  +
                </button>
              </div>
            </div>
          )}
          {cost - cost * (sale / 100) !== 0 ? (
            <div
              className={
                "flex flex-col justify-center items-center content-center w-[90%] h-[90px] relative mb-2"
              }
            >
              <span className={"mb-1 opacity-50 text-left w-full"}>
                Стоимость
              </span>

              <div
                className={
                  "w-full h-[34px] bg-neutral-900 border border-orange-600 flex justify-between rounded-r-lg items-center content-between"
                }
              >
                <span className={"ml-2"}>
                  {(cost - cost * (sale / 100)) * Number(value / minCount)}
                </span>
                <div
                  className={
                    "flex justify-content items-center content-center w-[100px] h-[34px] bg-orange-600 rounded-tr-lg rounded-br-lg"
                  }
                >
                  <span className={"text-center w-full font-extrabold"}>
                    RUB
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="m-2 h-1"></div>
          )}
          <div
            className={
              "w-[90%] h-full flex justify-between content-between items-center"
            }
          >
            <button
              disabled={disable}
              onClick={() => {
                setAnim(true);
                setTimeout(() => close(), 800);
              }}
              className={
                "mb-4 mr-2 w-[132px] h-[32px] relative bg-[#FF5318] rounded-md text-stone-950 text-[12px] font-black"
              }
            >
              ЗАКРЫТЬ
            </button>
            <button
              disabled={disable}
              onClick={onClickBuy}
              className={
                "mb-4 ml-2 w-[132px] h-[32px] relative bg-[#FF5318] rounded-md text-[12px] font-black"
              }
            >
              {cost - cost * (sale / 100) !== 0 ? "КУПИТЬ" : "ПРИНЯТЬ"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
