import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "../../api/axios";

export interface CartResponse {
  id: number;
  itemName: string;
  itemImage: string;
  status: string ;
  count: number;
  cost: number;
}

export interface PageCart {
  items: CartResponse[];
  allCount: number;
}

interface IInitialState extends PageCart {
  status: "loading" | "complete" | "error";
}

interface ICart {
  list: number;
  limit: number;
}

const initialState: IInitialState = {
  items: [],
  allCount: 0,
  status: "loading",
};

export const getCartList = createAsyncThunk<PageCart, ICart>(
  "items/getCartList",
  async (payload) => {
    const response = await axios.get(
      `/cart/getitems?page=${payload.list}&limit=${payload.limit}`,
    );
    return response.data;
  },
);
export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCartList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getCartList.fulfilled,
        (state, action: PayloadAction<PageCart>) => {
          state.items = action.payload.items;
          state.allCount = action.payload.allCount;
          state.status = "complete";
        },
      )
      .addCase(getCartList.rejected, (state) => {
        state.status = "error";
      });
  },
});

export const {} = cartSlice.actions;
export const getCart = (state: RootState) => state.cart;

export default cartSlice.reducer;
