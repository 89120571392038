import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import React, {useEffect} from "react";
import Loading from "../loading/Loading";
import {PaginationCart} from "../../../app/filter/filterSlice";
import {getHistory, getHistoryList} from "../../../app/history/historySlice";
import {Pagination} from "./Pagination";

export const History: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useAppSelector(getHistory);
  const page = useAppSelector(PaginationCart);
  useEffect(() => {
    dispatch(getHistoryList({ list: page, limit: 5 }));
  }, [page]);

  const cartTitles = ["Дата", "Подробно", "Сумма"];
  const titlesMap = (title: string) => (
    <th
      scope="col"
      className="px-6 font-bold bg-[#242424] py-4 text-center text-amber-500 "
    >
      {title}
    </th>
  );
  return (
    <>
      {history.status === "loading" && history.items.length === 0 && (
        <div className="w-[200px] h-[200px]">
          <Loading />
        </div>
      )}
      <table className="min-w-full">
        <thead className="bg-slate-50 border-b border-slate-200">
          <tr>{cartTitles.map(titlesMap)}</tr>
        </thead>
        <tbody>
          {history.items.map((item) => {
            return (
              <tr
                key={item.id}
                className="px-6 py-4 odd:bg-[#1C1C1C] even:bg-[#242424] select-none"
              >
                <td className="px-6 py-4 text-center font-[16px]">
                  {new Date(item.createAt).toLocaleString("ru-RU")}
                </td>
                <td className="px-6 py-4 text-center font-[16px]">
                  {item.custom}
                </td>
                <td className="px-6 py-4 text-center font-[16px]">
                  {item.count}
                </td>
              </tr>
            );
          })}
          {5 - history.items.length !== 0 &&
              Array(5 - history.items.length).map((i) => (
                  <tr
                      key={cartTitles[i]}
                      className="px-6 py-4 odd:bg-[#1C1C1C] even:bg-[#242424] select-none"
                  ></tr>
              ))}
        </tbody>
      </table>
      <Pagination maxCount={history.allCount} step={5} />
    </>
  );
};
