import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  fetchSession,
  selectUser,
  selectUserStatus,
} from "../../../app/user/userSlice";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { styled } from "@mui/material/styles";
import Items from "./src/pages/items/Items";
import Users from "./src/pages/users/Users";
import Promos from "./src/pages/promos/Promos";
import Category from "./src/pages/category/Category";
import Servers from "./src/pages/servers/Servers";
import Transactions from "./src/pages/transactions/Transactions";
import History from "./src/pages/histroy/History";

function Admin() {
  const items = [
    { name: "Статистика", href: "" },
    { name: "Пользователи", href: "users" },
    { name: "Предметы", href: "items" },
    { name: "Промокоды", href: "promos" },
    { name: "Категории", href: "category" },
    { name: "Сервера", href: "servers" },
    { name: "Транзакции", href: "transactions" },
    { name: "История", href: "history" },
  ];
  const user = useAppSelector(selectUser);
  const status = useAppSelector(selectUserStatus);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const currentRoute = location.pathname.split("/").pop() ?? "";
  console.log(currentRoute);
  const selectTab = [...items.map((p) => p.href)].indexOf(
    currentRoute === "admin" ? "" : currentRoute,
  );
  useEffect(() => {
    dispatch(fetchSession());
  }, []);
  if (
    ((!user || !user.isAdmin) && status === "loaded") ||
    status === "failed"
  ) {
    return <Navigate to={"/"} />;
  }
  return (
    <div className={"relative min-w-full min-h-screen bg-black text-white"}>
      <Box className={"flex"}>
        <AppBar className={"fixed"} open={open}>
          <Toolbar className={"bg-[#0E0E0E]"}>
            <IconButton
              onClick={() => setOpen(true)}
              color="inherit"
              aria-label="open drawer"
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Админ панель - {items[selectTab].name}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: 240,
            flexShrink: 0,
            color: "#FFFFFF",
            "& .MuiDrawer-paper": {
              width: 240,
              backgroundColor: "#0E0E0E",
              color: "#FFFFFF",
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={() => setOpen(false)}>
              <ChevronLeftIcon className={"text-white"} />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {items.map((text, index) => (
              <ListItem
                key={text.name}
                disablePadding
                className={
                  selectTab === index ? "border-b border-amber-500 " : ""
                }
              >
                <ListItemButton component={Link} to={`/admin/${text.href}`}>
                  <ListItemIcon>
                    {index % 2 === 0 ? (
                      <InboxIcon className={"text-white"} />
                    ) : (
                      <MailIcon className={"text-white"} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={text.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <Routes>
            <Route path={""} element={<p>21321312</p>} />
            <Route path={"/"} element={<p>21321312</p>} />
            <Route path={"users"} element={<Users />} />
            <Route path={"items"} element={<Items />} />
            <Route path={"promos"} element={<Promos />} />
            <Route path={"category"} element={<Category />} />
            <Route path={"servers"} element={<Servers />} />
            <Route path={"transactions"} element={<Transactions />} />
            <Route path={"history"} element={<History />} />
          </Routes>
        </Main>
      </Box>
    </div>
  );
}
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${240}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${240}px)`,
    marginLeft: `${240}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
export default Admin;
