import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "../../api/axios";

export interface TranscationSlice {
  id: number;
  amount: number;
  status: "loaded" | "loading" | "failed";
}

const initialState: TranscationSlice = {
  id: 0,
  amount: 1,
  status: "loading",
};
export const createPayment = createAsyncThunk(
  "transaction/createPayment",
  async (amount: number) => {
    try {
      const response = await axios.post("/payment/create-payment", {
        amount,
      });

      return response.data;
    } catch (err) {
      throw err;
    }
  },
);
export const getPayment = createAsyncThunk(
  "transaction/getPayment",
  async (id: number) => {
    try {
      const response = await axios.get("/payment/getPayment/" + id);
      return response.data;
    } catch (err) {
      throw err;
    }
  },
);
export const updatePayment = createAsyncThunk(
  "transaction/updatePayment",
  async ({ id, type }: { id: number; type: string }) => {
    try {
      const { data } = await axios.patch<string>(`/payment/${id}`, {
        type,
      });
      return data;
    } catch (err) {
      throw err;
    }
  },
);
export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setAmount(state, action: PayloadAction<number>) {
      state.amount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPayment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.status = "loaded";
        state.id = action.payload;
      })
      .addCase(createPayment.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updatePayment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePayment.fulfilled, (state) => {
        state.status = "loaded";
      })
      .addCase(updatePayment.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getPayment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPayment.fulfilled, (state, action) => {
        state.status = "loaded";
        state.amount = action.payload.amount;
        state.id = action.payload.id;
      })
      .addCase(getPayment.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setAmount } = transactionSlice.actions;
export const selectTransaction = (state: RootState) => state.transaction;

export default transactionSlice.reducer;
