import React, { useEffect, useState } from "react";
import Loading from "../../../../../components/loading/Loading";
import axios from "../../../../../../api/axios";
import { ICategoryAdminResponse } from "../../../../../../api/admin.entity";
import EditIcon from "@mui/icons-material/Edit";

function Category() {
  const [items, setItems] = useState<ICategoryAdminResponse>();
  useEffect(() => {
    const fetchUsers = async () => {
      const { data } = await axios.get<ICategoryAdminResponse>(
        "/admin/allCategory?page=1&limit=10",
      );
      setItems(data);
    };
    fetchUsers();
  }, []);
  if (!items) {
    return <Loading />;
  }
  return (
    <div className={"w-full h-full"}>
      <table className={"min-w-full h-full"}>
        <thead>
          <tr
            className={
              "text-amber-500 font-bold bg-[#242424] h-10 rounded-t-full rounded-tl-full rounded-tr-full border-b border-b-slate-500"
            }
          >
            <th className={"pl-4 pr-4"}>ID</th>
            <th className={"pl-4 pr-4"}>Название</th>
            <th className={"pl-4 pr-4"}>Предметов</th>
            <th className={"pl-4 pr-4"}>Управление</th>
          </tr>
        </thead>
        <tbody>
          {items.items.map((item) => (
            <tr
              key={item.id}
              className={
                "odd:bg-[#1C1C1C] even:bg-[#242424] " +
                "text-white bg-[#1C1C1C] text-center h-10 border-b border-b-slate-500 last:border-b-0 last:rounded-bl-full last:rounded-br-full last:rounded-b-full"
              }
            >
              <td className={"pl-4 pr-4"}>{item.id}</td>
              <td className={"pl-4 pr-4"}>{item.name}</td>
              <td className={"pl-4 pr-4"}>{item.itemsCount}</td>

              <td className={"pl-4 pr-4"}>
                <button className={"rounded-full bg-emerald-600 w-7 h-7 "}>
                  <EditIcon className={"scale-90 text-white select-none"} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Category;
