import { fetchSession, Session } from "../../../app/user/userSlice";
import axios from "../../../api/axios";
import { useAppDispatch } from "../../../app/hooks";
import { Link, useNavigate } from "react-router-dom";
import React from "react";

interface IMainInfoProfile {
  user: Session;
}

export const MainInfoProfile: React.FC<IMainInfoProfile> = ({ user }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const logout = async () => {
    navigate("/");
    await axios.get("/user/logout");
    dispatch(fetchSession());
  };
  const itemClass =
    "p-3 first:rounded-t-xl last:rounded-b-xl flex justify-between odd:bg-[#1C1C1C] even:bg-[#242424]";
  return (
    <div className="flex gap-10 justify-center items-center">
      <img
        className="w-52 h-52 border-2 border-amber-500 rounded-3xl"
        src={user.imageUrl}
        alt={user.name}
      />
      <div className="flex  flex-col w-96">
        <div className={itemClass}>
          <span>Ваш ник</span>
          <span className="text-amber-500">{user.name}</span>
        </div>
        <div className={itemClass}>
          <span>SteamID</span>
          <Link
            target={"_blank"}
            to={`https://steamcommunity.com/profiles/${user.steamid}`}
            className="text-amber-500"
          >
            {user.steamid}
          </Link>
        </div>
        <div className={itemClass}>
          <span>Баланс</span>
          <span className="text-amber-500">{`${user.balance} RUB`}</span>
        </div>
        <div
          className="p-3 first:rounded-t-xl last:rounded-b-xl flex justify-center text-rose-500 cursor-pointer odd:bg-[#1C1C1C] even:bg-[#242424]"
          onClick={logout}
        >
          Выйти
        </div>
      </div>
    </div>
  );
};
