import React from "react";

function Info() {
  return (
    <div className="mt-2 w-full h-7 relative bg-[#1B1B1B] flex justify-center content-center items-center">
      <svg
        className="mr-3"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.409 14.9718C12.409 15.9876 11.5543 16.8111 10.4999 16.8111C9.44561 16.8111 8.59085 15.9876 8.59085 14.9718C8.59085 13.956 9.44561 13.1326 10.4999 13.1326C11.5543 13.1326 12.409 13.956 12.409 14.9718Z"
          fill="#1B1B1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 0.257812C4.70101 0.257812 0 4.78685 0 10.3737C0 15.9605 4.70101 20.4896 10.5 20.4896C16.299 20.4896 21 15.9605 21 10.3737C21 4.78685 16.299 0.257812 10.5 0.257812ZM10.4999 16.8111C11.5543 16.8111 12.409 15.9876 12.409 14.9718C12.409 13.956 11.5543 13.1326 10.4999 13.1326C9.44561 13.1326 8.59085 13.956 8.59085 14.9718C8.59085 15.9876 9.44561 16.8111 10.4999 16.8111ZM12.409 3.44666H8.59085L9.24 11.5153H11.76L12.409 3.44666Z"
          fill="#686868"
        />
        <path
          d="M8.59085 3.44666H12.409L11.76 11.5153H9.24L8.59085 3.44666Z"
          fill="#1B1B1B"
        />
      </svg>

      <div className="text-stone-300 text-xs">
        Если у вас случились какие то неприятности с работой сайта пишите к нам
        в дискорд
      </div>
    </div>
  );
}

export default Info;
