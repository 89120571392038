import React from "react";
import Item from "../../components/item/Item";
import { useAppSelector } from "../../../app/hooks";
import { getItems } from "../../../app/items/itemSlice";
import {
  getOpenItem,
  selectCategory,
  serverSelect,
} from "../../../app/filter/filterSlice";

function Container() {
  const { items } = useAppSelector(getItems);

  const selectServer = useAppSelector(serverSelect);
  const category = useAppSelector(selectCategory);
  const itemOpen = useAppSelector(getOpenItem);
  // const [rustItems, setRustItems] = useState<RustItem[]>([]);
  //
  // useEffect(() => {
  //   const test = async () => {
  //     const { data } = await axios.get<RustItem[]>(
  //       "https://raw.githubusercontent.com/rostov114/rust-items/main/items.json",
  //     );
  //     console.log(data);
  //     setRustItems(data);
  //   };
  //   test();
  // }, []);
  return (
    <div className="w-full sm:w-[750px] md:w-[750px] mt-10 mb-10 grid md:grid-cols-4 gap-x-[25px] gap-y-[25px] bg-black grid-cols-1 sm:grid-cols-3 ">
      {/*{[...rustItems].map((p) => (*/}
      {/*  <SkeletonItem name={p.name} image={p.image} />*/}
      {/*))}*/}
      {items
        .filter(
          (p) =>
            (p.serverId === null || p.serverId === selectServer) &&
            (category === 0 || category === p.categoryId),
        )
        .map((p) => (
          <Item
            id={p.id}
            minCount={p.minCount}
            key={p.id}
            name={p.name}
            description={p.description}
            imageUrl={p.imageUrl}
            cost={p.cost}
            sale={p.sale}
            type={p.type}
            isOpen={itemOpen === p.id}
          />
        ))}
    </div>
  );
}

export default Container;
