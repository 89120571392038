import React from "react";
import ItemFull from "../itemfull/ItemFull";
import {useAppDispatch} from "../../../app/hooks";
import {closeItem, openItem} from "../../../app/filter/filterSlice";
import {useNavigate} from "react-router-dom";
import qs from "qs";

function Item({
  name,
  id,
  description,
  cost,
  sale,
  imageUrl,
  minCount,
  type,
  isOpen = false,
}: {
  id: number;
  name: string;
  description: string;
  cost: number;
  sale: number;
  minCount: number;
  imageUrl: string;
  type: string;
  isOpen: boolean;
}) {
  console.log(id, !isOpen);
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const onClickOpen = () => {
    dispatch(openItem(id));
    const { category, server } = qs.parse(window.location.search.substring(1));
    const queryString = qs.stringify({
      category,
      server,
      item: id,
    });

    navigation(`/?${queryString}`);
  };
  const onClickClose = () => {
    dispatch(closeItem());
    const { category, server } = qs.parse(window.location.search.substring(1));
    const queryString = qs.stringify({
      category,
      server,
    });
    navigation(`/?${queryString}`);
  };
  return (
    <>
      {isOpen && (
        <ItemFull
          id={id}
          minCount={minCount}
          name={name}
          description={description}
          sale={sale}
          cost={cost}
          imageUrl={imageUrl}
          hidden={!isOpen}
          close={onClickClose}
          type={type}
        />
      )}
      <div
        onClick={() => onClickOpen()}
        className={
          "border border-[#1B1B1B] col-span-1 m-auto rounded-xl bg-[#0E0E0E] w-[255px] h-[400px] sm:w-[175px] sm:h-[220px] flex flex-col flex-shrink-0 justify-center items-center content-center transform transition-all ease-in-out duration-500 hover:scale-125 cursor-pointer  "
        }
      >
        <span
          className={
            "select-none  mt-2 text-white text-[20px] sm:text-[13px] font-semibold uppercase"
          }
        >
          {name}
        </span>
        <img
          loading="lazy"
          className={
            "mt-7 sm:mt-0 w-[300px] h-[300px] sm:w-[136px] sm:h-[136px] select-none"
          }
          src={imageUrl}
          alt={"Icon"}
        />
        <button
          className={
            "bg-[#FF5318ED] rounded-b-xl text-white w-full h-full mt-10 sm:mt-6 flex justify-center items-center content-center"
          }
        >
          {sale > 0 && (
            <span
              className={
                "text-[10px] font-extrabold line-through opacity-[0.6]"
              }
            >
              {cost} RUB
            </span>
          )}

          <svg
            className={"ml-0 mr-1"}
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="16"
            viewBox="0 0 23 16"
            fill="none"
          >
            <ellipse
              cx="9.35327"
              cy="13.6711"
              rx="1.33618"
              ry="1.33618"
              fill="white"
            />
            <path
              d="M8.68532 10.6038L20.0429 9.7687L19.6367 3.95094L6.29888 3.82409L8.68532 10.6038Z"
              fill="white"
            />
            <path
              d="M20.0429 12.3349H8.68532V10.6038M8.68532 10.6038L20.0429 9.7687L19.6367 3.95094L6.29888 3.82409M8.68532 10.6038L6.29888 3.82409M6.29888 3.82409L5.84584 2.67023H3.75806"
              stroke="#F2F9FE"
              strokeWidth="1.29135"
            />
            <ellipse
              cx="19.7087"
              cy="14.0278"
              rx="1.33618"
              ry="1.33618"
              fill="white"
            />
          </svg>
          <span className={"font-extrabold text-[14px]"}>
            {cost - cost * (sale / 100) === 0 ? 'Бесплатно' : cost - cost * (sale / 100) + ' RUB'}
          </span>
        </button>
      </div>
    </>
  );
}

export default Item;
