import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "../../api/axios";
export interface Item {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
  cost: number;
  minCount: number;
  type: string;
  sale: number;
  categoryId: null | number;
  serverId: null | number;
}

export interface ItemSlice {
  items: Item[];
  status: "loaded" | "loading" | "failed";
}

const initialState: ItemSlice = {
  items: [],
  status: "loading",
};
export const getAllItems = createAsyncThunk("items/getAllItems", async () => {
  const response = await axios.get("/item/");
  return response.data;
});

export const itemSlice = createSlice({
  name: "items",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllItems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllItems.fulfilled, (state, action) => {
        state.status = "loaded";
        state.items = action.payload;
      })
      .addCase(getAllItems.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = itemSlice.actions;
export const getItems = (state: RootState) => state.items;

export default itemSlice.reducer;
