import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "../../api/axios";

export interface LogResponse {
  id: number;
  count: string;
  custom: string;
  createAt: string;
}
export interface PageLog {
  items: LogResponse[];
  allCount: number;
}

interface IInitialState extends PageLog {
  status: "loading" | "complete" | "error";
}

interface IHistory {
  list: number;
  limit: number;
}

const initialState: IInitialState = {
  items: [],
  allCount: 0,
  status: "loading",
};

export const getHistoryList = createAsyncThunk<PageLog, IHistory>(
  "history/getHistoryList",
  async (payload) => {
    const response = await axios.get(
      `/logs/getitems?page=${payload.list}&limit=${payload.limit}`,
    );
    return response.data;
  },
);
export const historySlice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHistoryList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getHistoryList.fulfilled,
        (state, action: PayloadAction<PageLog>) => {
          state.items = action.payload.items;
          state.allCount = action.payload.allCount;
          state.status = "complete";
        },
      )
      .addCase(getHistoryList.rejected, (state) => {
        state.status = "error";
      });
  },
});

export const getHistory = (state: RootState) => state.history;

export default historySlice.reducer;
