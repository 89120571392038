import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import transactionReducer from "./transaction/transactionSlice";
import itemReducer from "./items/itemSlice";
import serverReducer from "./servers/serverSlice";
import cartReducer from "./cart/cartSlice";
import categoryReducer from "./category/categorySlice";
import filterReducer from "./filter/filterSlice";
import historyReducer from "./history/historySlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    transaction: transactionReducer,
    items: itemReducer,
    servers: serverReducer,
    cart: cartReducer,
    history: historyReducer,
    category: categoryReducer,
    filter: filterReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
